export enum ACTION_WORKER {
  START,
  FINISH,
}

export interface IWorkerLocalStorage {
  currentProjectEncryptionKey: string
  projectEncryptionKeys: {
    [key: string]: string
  }
  currentConversationEncryptionKey: string
  conversationEncryptionKeys: {
    [key: string]: string
  }
  conversationNoteEncryptionKey: string
}
