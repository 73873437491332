import Axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { encryptionHelper } from "helpers/encryption"
import {
  getContentFromConversationNoteFile,
  getPrivateAssetURI,
  validateEncryptedFile,
  getFileContentFromEnrypted,
} from "helpers/utils"
import { STATUS_RESPONSE } from "types"
import {
  BuildConversationDetail,
  BuildInviteesConversationDetail,
  CONVERSATION_ROLE,
  ConversationDetail,
  ConversationMessagesDetail,
  CustomStatus,
  InviteeAddColumnDetail,
  Note,
  ParamsBuildConversationProps,
  ConversationShareKeys,
  ConversationKeyResponse,
} from "./conversations.type"
import { ComponentType } from "pages/project-component-detail/types"
import { ACTION_WORKER } from "workers/type"
import { getWorkerLocalStorageData } from "workers/utils"
import { getTokenByInvitee } from "pages/auth/services/api"

export const getListConversationByBuildMiddleware = async (
  urlTab: string,
  params?: ParamsBuildConversationProps
) => {
  const response: AxiosResponse<{
    data: BuildConversationDetail[]
  }> = await Axios.get(`/api/conversation/${urlTab}`, { params })
  return response.data.data
}
export const getListConversationMiddleware = async (
  tab: string,
  params?: ParamsBuildConversationProps
) => {
  const response: AxiosResponse<{
    data: ConversationDetail[]
    encryptedKeys?: any
  }> = await Axios.get(`/api/conversation/${tab}`, { params })
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const projectEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
  )
  if (response.data.encryptedKeys) {
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string
    const decryptedKeys = response.data.encryptedKeys.reduce((pre, cur) => {
      return {
        ...pre,
        [cur.id]: !cur.projectId
          ? encryptionHelper.decrypt(userVaultKey, cur.encryptedKey)
          : encryptionHelper.decrypt(
              projectEncryptionKeys[cur.projectId],
              cur.encryptedKey
            ),
      }
    }, {})
    localStorage.setItem(
      EncryptionKeys.conversationEncryptionKeys,
      JSON.stringify({
        ...conversationEncryptionKeys,
        ...decryptedKeys,
      })
    )
    return response.data.data.map((item) => {
      const decryptedKey = decryptedKeys[item.id]
      return {
        ...item,
        message: {
          ...item.message,
          content: !decryptedKey
            ? item.message.content
            : (encryptionHelper.decrypt(
                decryptedKey,
                item.message.content
              ) as string),
        },
      }
    })
  }
  return response.data.data
}
export const getConversationByBuildMiddleware = async (
  projectBuildId: string,
  urlConversationByBuild: string
) => {
  const response: AxiosResponse<{
    data: BuildInviteesConversationDetail[]
  }> = await Axios.get(
    `/api/conversation/${urlConversationByBuild}/${projectBuildId}`
  )
  return response.data.data
}
export const getConversationMessagesMiddleware = async (
  conversationId: string,
  params?: {
    page: number
    pageSize: number
  }
) => {
  const response: AxiosResponse<{
    data: ConversationMessagesDetail
  }> = await Axios.get(`/api/conversation/${conversationId}/messages`, {
    params,
  })
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  localStorage.setItem(
    EncryptionKeys.currentConversationEncryptionKey,
    conversationEncryptionKeys[conversationId] || ""
  )
  const decryptedMessages = await Promise.all(
    response.data.data.messages.map(async (item) => {
      let decrypted = await encryptionController().decrypt(item.content, {
        dataType: "string",
        type: "conversation",
      })
      if (decrypted === item.content) {
        decrypted = await encryptionController().decrypt(item.content, {
          dataType: "string",
          type: "project",
        })
      }
      const fileDatas = await Promise.all(
        item.files.map(async (file) => {
          const fileUrl = getPrivateAssetURI(file.file, {
            conversation_id: conversationId,
          })
          const base64Str = await getFileContentFromEnrypted(
            fileUrl,
            new Date().toISOString(),
            true,
            undefined,
            { resutlType: "base64" }
          )
          return {
            ...file,
            base64Str,
          }
        })
      )
      const includeImageIntoText = (content: string, files: any) => {
        let newContent = content
        files.forEach((rawFile) => {
          newContent = newContent.replace(rawFile.id, rawFile.base64Str)
        })
        return newContent
      }
      return {
        ...item,
        content: includeImageIntoText(decrypted, fileDatas),
      }
    })
  )
  return { ...response.data.data, messages: decryptedMessages }
}
export const getConversationKeyMiddleware = async (conversationId: string) => {
  const response: AxiosResponse<ConversationShareKeys> = await Axios.get(
    `/api/conversation/${conversationId}/key`
  )

  return response.data
}
export const getConversationKeysMiddleware = async () => {
  const response: AxiosResponse<ConversationKeyResponse> = await Axios.get(
    `/api/conversation/keys`
  )

  return response.data.data
}
export type SharedKeys = {
  data: {
    conversationKey?: string
    projectKey: string
  }
  statusCode: number
}
export const getSharedKeysMiddleware = async (
  email: string,
  projectId: string,
  conversationId?: string
) => {
  const response: AxiosResponse<SharedKeys> = await Axios.get(
    `/api/encryption/shared-keys?email=${email}&project_id=${projectId}${
      conversationId ? `&conversation_id=${conversationId}` : ""
    }`
  )

  return response.data.data
}

export const postConversationLikeBuildMiddleware = (
  idProjectBuild: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/conversation/like-build/${idProjectBuild}`)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.FAVORITE_PROJECT_BUILD_ERROR
      )
    })
}

export const postConversationSendMessageMiddleware = async (
  worker: Worker,
  conversationId: string,
  data: {
    content: string
    files: {
      file_name: string
      file: File
      file_type: string
    }[]
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  inviteeUser?: any
) => {
  const multipart = new FormData()

  const dataInvitee = {
    encryptedProjectKey: "",
    encryptedConversationKey: "",
    token: "",
  }

  if (!inviteeUser.is_sent) {
    const userToken = await getTokenByInvitee({
      userId: inviteeUser.user_id,
      conversationId,
    })
    if (userToken) {
      const currentProjectEncryptionKey = localStorage.getItem(
        EncryptionKeys.currentProjectEncryptionKey
      ) as string
      const conversationEncryptionKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
      )
      const currentConversationEncryptionKey =
        conversationEncryptionKeys[conversationId]
      const encryptedProjectKey = encryptionHelper.encrypt(
        userToken,
        currentProjectEncryptionKey
      )
      const encryptedConversationKey = encryptionHelper.encrypt(
        userToken,
        currentConversationEncryptionKey
      )
      dataInvitee.encryptedProjectKey = encryptedProjectKey
      dataInvitee.encryptedConversationKey = encryptedConversationKey
      dataInvitee.token = userToken
    }
  }

  if (!inviteeUser.is_sent && !dataInvitee.token) {
    callback(
      STATUS_RESPONSE.ERROR,
      MESSENGER_NOTIFICATION.CONVERSATION_SEND_MESSAGE_ERROR
    )
    return
  }

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: data.files,
      localStorageData: getWorkerLocalStorageData(),
      content: data.content,
      contentOption: {
        dataType: "string",
        type: "conversation",
        relationId: conversationId,
      },
      fileOption: {
        dataType: "file",
        type: "conversation",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments, contentKey } = e.data.payload
      attachments.forEach((attachment) => {
        multipart.append("attachments", attachment.blob, attachment.fileName)
      })

      const dataRequest = {
        ...data,
        files: fileHashs,
        content: contentKey,
        raw_content: data.content,
        log: activityLogHelper.toEncryptedMessage(
          ActivityLogType.SendInvitation,
          {}
        ),
      }
      if (dataInvitee.token) {
        dataRequest["encryptedProjectKey"] = dataInvitee.encryptedProjectKey
        dataRequest["encryptedConversationKey"] =
          dataInvitee.encryptedConversationKey
        dataRequest["token"] = dataInvitee.token
      }
      multipart.append("data", JSON.stringify(dataRequest))

      Axios.post(`/api/conversation/${conversationId}/send-message`, multipart)
        .then(async (response: AxiosResponse) => {
          callback(STATUS_RESPONSE.SUCCESS, "")
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                  conversation_id: conversationId,
                },
                response.data.data.project_id
              )
              return true
            })
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.CONVERSATION_SEND_MESSAGE_ERROR
          )
        })
    }
  }
}
export const postAddInviteeColumnMiddleware = async (
  conversationId: string,
  projectComponentHistoryId: string,
  dataRequest: InviteeAddColumnDetail[],
  oldData: InviteeAddColumnDetail[],
  isUpdateColumn: boolean,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const diffColumn = activityLogHelper.toDiffColumn(oldData, dataRequest)
  const log = activityLogHelper.createAddColumnLog(
    ComponentType.BOM,
    diffColumn
  )
  const newData = await Promise.all(
    dataRequest.map(async (item) => {
      const encryptedValues = await encryptionController().encrypt(
        JSON.stringify(item.values),
        { dataType: "string", type: "project" }
      )
      return {
        ...item,
        values: encryptedValues,
      }
    })
  )
  Axios.post(
    `/api/bom/${projectComponentHistoryId}/${conversationId}/add-invitee-column`,
    {
      data: newData,
      log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddBomColumn, {
        content: log,
      }),
    }
  )
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `${isUpdateColumn ? "Updated" : "Added"} column successfully!`
      )
    })
    .catch((error) => {
      const newMessageError = `${
        isUpdateColumn ? "Updated" : "Added"
      } column failed!`
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : newMessageError
      )
    })
}

export const getNotificationConversationMiddleware = async (
  conversationId: string
) => {
  const response: AxiosResponse<{
    data: {
      is_notified: boolean
    }
  }> = await Axios.get(`/api/conversation/${conversationId}/is-notify`)
  return response.data.data
}
export const postNotificationConversationMiddleware = (
  conversationId: string,
  label: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/conversation/${conversationId}/update-is-notify`)
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `Turn ${label} email notification successfully!`
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message
          ? error.response?.data?.message
          : `Turn ${label} email notification fail!`
      )
    })
}

export const archiveConversationMiddleware = async (conversationId: string) => {
  const res = await Axios.post(`/api/conversation/archive/${conversationId}`)
  return res.data
}
export const getCustomStatusesMiddleware = async () => {
  const res = await Axios.get("/api/conversation/custom-statuses/get")
  return res.data.data
}
export const createCustomStatusMiddleware = async (
  customStatus: CustomStatus
) => {
  const res: AxiosResponse<{
    data: CustomStatus
  }> = await Axios.post(`/api/conversation/custom-status/create`, {
    custom_status: { name: customStatus.name, color: customStatus.color },
  })
  return res.data.data
}
export const updateCustomStatusesMiddleware = async (
  customStatus: CustomStatus
) => {
  const res = await Axios.post(
    `/api/conversation/custom-statuses/${customStatus.id}/update`,
    {
      custom_status: { name: customStatus.name, color: customStatus.color },
    }
  )
  return res.data
}
export const deleteCustomstatusMiddleware = async (
  customStatus: CustomStatus
) => {
  const res = await Axios.delete(
    `/api/conversation/custom-status/${customStatus.id}/delete`
  )
  return res.data
}
export const updateCustomStatusMiddleware = async (
  conversationId: string,
  customStatus?: CustomStatus
) => {
  const res = await Axios.post(
    `/api/conversation/${conversationId}/custom-status`,
    { custom_status_id: customStatus?.id }
  )
  return res.data
}
export const updateNotesMiddleware = async (
  conversationId: string,
  notes: string
) => {
  const encryptedNote = await encryptionController().encrypt(notes, {
    type: "conversation_note",
    dataType: "string",
  })
  const res = await Axios.post(`/api/conversation/notes/${conversationId}`, {
    notes: encryptedNote,
  })
  return res.data
}
export const getUserNoteFilesMiddleware = async (): Promise<
  { file_key: string; is_synced: boolean }[]
> => {
  const res = await Axios.get(`/api/conversation/user-note-files?loading=0`)
  return res.data.data
}
export const postSyncNoteFileMiddleware = async (payload: any) => {
  let url = `/api/conversation/sync-note-file?loading=0`
  return Axios.post(url, payload)
}

export const deleteNoteImageMiddleware = async (
  conversationId: string,
  fileKey: string
) => {
  const res = await Axios.delete(
    `/api/conversation/notes/${conversationId}/delete-image/${encodeURIComponent(
      fileKey
    )}`
  )
  return res.data.data
}

export const deleteNotesFolder = async (conversationId: string) => {
  const res = await Axios.delete(
    `/api/conversation/${conversationId}/notes/delete-folder`
  )
  return res.data
}

export const likeConversationMiddleware = async (conversationId: string) => {
  const res = await Axios.post(`/api/conversation/like/${conversationId}`)
  return res.data
}

export const getNoteMiddleware = async (conversationId: string) => {
  const res: AxiosResponse<{
    data: Note
  }> = await Axios.get(`/api/conversation/get-notes/${conversationId}`)
  const conversationNoteKey =
    localStorage.getItem(EncryptionKeys.conversationNoteEncryptionKey) || ""
  let decryptedNote = await encryptionController().decrypt(res.data.data.note, {
    dataType: "string",
    type: "conversation_note",
    encryptionKey: conversationNoteKey,
  })
  const fileDatas = await Promise.all(
    res.data.data.files.map(async (file) => {
      const fileUrl = getPrivateAssetURI(file.file, {})
      const base64Str = await getContentFromConversationNoteFile(fileUrl, true)
      return {
        ...file,
        url: base64Str as string,
      }
    })
  )
  const includeImageIntoText = (content: string, files: any) => {
    let newContent = content
    files.forEach((rawFile) => {
      newContent = newContent.replace(rawFile.file, rawFile.url)
    })
    return newContent
  }
  const noteWithImages = includeImageIntoText(decryptedNote, fileDatas)
  return {
    ...res.data.data,
    files: fileDatas,
    note: noteWithImages,
  }
}

export const getConversationRoleMiddleWare = async (
  projectBuildComponentId: string,
  conversationId: string
) => {
  const res: AxiosResponse<{
    data: {
      role: CONVERSATION_ROLE
    }
  }> = await Axios.get(
    `/api/conversation/${projectBuildComponentId}/${conversationId}/get-role`
  )
  return res.data.data
}
