import {
  ParamsProjectComponentProps,
  ProjectComponentDetail,
  CreateProjectComponentRequest,
  UpdateProjectComponentRequest,
  CreateBuildRequest,
  CreateShareLink,
  ParamsGetUserNotInProject,
  RoleOptionsData,
  SetRoleInProjectProps,
  GetUsersInProjectProp,
  GetActivitiesLogData,
  ParamsGetActivitiesLog,
  BuildStatusProp,
  ChangeBuildStatusData,
  ProjectBuildDetail,
  Folder,
} from "../types"
import Axios, { AxiosResponse } from "axios"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { PostCommentBodyData } from "pages/project-component-detail/types"
import { ProjectComponentBuildDetail } from "pages/project-build/project-build.type"
import { GetCommentsData } from "components/comment/types"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"

export const getProjectComponentsMiddleware = async (
  idProject: string,
  params?: ParamsProjectComponentProps
) => {
  const response: AxiosResponse<{
    data: {
      components: ProjectComponentDetail[]
      groups: Folder[]
    }
  }> = await Axios.get(`/api/project-component/get-by-project/${idProject}`, {
    params,
  })
  return response.data.data
}

export const getProjectComponentDetailMiddleware = async (
  idComponent: string,
  idConversation?: string
) => {
  let url = `/api/project-component/${idComponent}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response = await Axios.get<{ data: ProjectComponentDetail }>(url)
  return response.data.data
}

export const postProjectComponentMiddleware = (
  request: CreateProjectComponentRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentDetail
  ) => void
) => {
  Axios.post(`/api/project-component/create`, {
    ...request,
    log: {
      createComponent: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CreateComponent,
        {}
      ),
      createComponentInFolder: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CreateComponentInFolder,
        {}
      ),
    },
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_PROJECT_COMPONENT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_PROJECT_COMPONENT_ERROR
      )
    })
}

export const putProjectComponentMiddleware = (
  idComponent: string,
  request: UpdateProjectComponentRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentDetail
  ) => void
) => {
  Axios.put(`/api/project-component/${idComponent}/update`, {
    ...request,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.UpdateComponentName,
      {}
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_PROJECT_COMPONENT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.PUT_PROJECT_COMPONENT_ERROR
      )
    })
}

export const getProjectBuildsMiddleware = async (
  idProject: string,
  params?: ParamsProjectComponentProps
) => {
  const response: AxiosResponse<{
    data: ProjectBuildDetail[]
  }> = await Axios.get(`/api/project-build/project/${idProject}`, {
    params,
  })
  return response.data.data
}

export const getBuildDetailMiddleware = async (idBuild: string) => {
  const response: AxiosResponse<{
    data: ProjectBuildDetail
  }> = await Axios.get(`/api/project-build/${idBuild}`)
  return response.data.data
}

export const getBuildComponentDetailMiddleware = async (
  projectBuildComponentId: string,
  idConversation?: string
) => {
  let url = `/api/project-build/component/${projectBuildComponentId}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: ProjectComponentBuildDetail
  }> = await Axios.get(url)
  return response.data.data
}

export const postDuplicateProjectComponentMiddleware = (
  idComponent: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentDetail
  ) => void
) => {
  Axios.post(`/api/project-component/${idComponent}/duplicate`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.DuplicateComponent,
      {}
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_DUPLICATE_COMPONENT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_DUPLICATE_COMPONENT_ERROR
      )
    })
}

export const postArchiveProjectComponentMiddleware = (
  idComponent: string,
  isArchive: boolean,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentDetail
  ) => void
) => {
  Axios.post(`/api/project-component/${idComponent}/archive`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.ArchivedProjectComponent,
      { archive: isArchive ? "archived" : "unarchived" }
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          isArchive
            ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_COMPONENT_SUCCESS
            : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_COMPONENT_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? isArchive
          ? MESSENGER_NOTIFICATION.ARCHIVE_PROJECT_COMPONENT_ERROR
          : MESSENGER_NOTIFICATION.UN_ARCHIVE_PROJECT_COMPONENT_ERROR
      )
    })
}

export const postCreateShareLink = (
  id: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: CreateShareLink
  ) => void
) => {
  Axios.post(`/api/project-build/${id}/share`)
    .then(
      (
        response: AxiosResponse<{
          data: CreateShareLink
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_COPY_LINK_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_COPY_LINK_ERROR
      )
    })
}

export const getUserNotInProject = async (
  projectId: string,
  params?: ParamsGetUserNotInProject
) => {
  try {
    const response = await Axios.get(
      `/api/user/get-users-not-in-project/${projectId}`,
      { params }
    )
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getRoleOptions = async () => {
  try {
    const response: AxiosResponse<{
      data: RoleOptionsData[]
    }> = await Axios.get(`/api/project/role-options`)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const setRoleInProject = async (
  projectId: string,
  data: SetRoleInProjectProps
) => {
  try {
    const response: AxiosResponse<{
      data: string
    }> = await Axios.post(`/api/project/${projectId}/set-role`, data)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const setRoleInProjectMiddleware = (
  projectId: string,
  data: SetRoleInProjectProps,
  isChangeRole: boolean,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post("/api/auth/get-token", {
    email: data.email,
  }).then((response) => {
    const token = response.data.token
    const projectEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const currentProjectEncryptionKey = projectEncryptionKeys[projectId]
    if (
      !encryptionController().isValidKeyEncryption(currentProjectEncryptionKey)
    ) {
      return callback(
        STATUS_RESPONSE.ERROR,
        MESSENGER_NOTIFICATION.INVALID_KEY_ENCRYPTION
      )
    }
    const encryptedKey = encryptionHelper.encrypt(
      token,
      currentProjectEncryptionKey
    )
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string
    // encrypt related conversation keys here
    Axios.get(`/api/auth/project-conversation-keys/${projectId}`).then(
      (response) => {
        const encryptedConversationKeys = response.data.data

        const rawKeys = encryptedConversationKeys.reduce((pre, cur) => {
          return {
            ...pre,
            [cur.id]: !cur.projectId
              ? encryptionHelper.decrypt(userVaultKey, cur.key)
              : encryptionHelper.decrypt(
                  projectEncryptionKeys[cur.projectId],
                  cur.key
                ),
          }
        }, {})
        // console.log(rawKeys)
        const encryptedConvoKeys = encryptionHelper.encrypt(
          token,
          JSON.stringify(rawKeys)
        )
        Axios.post(`/api/project/${projectId}/set-role-by-email`, {
          ...data,
          encryptedKey,
          encryptedConvoKeys,
          token,
        })
          .then((_response: AxiosResponse) => {
            const newMessageSuccessLocal = isChangeRole
              ? MESSENGER_NOTIFICATION.POST_CHANGE_ROLE_SUCCESS
              : MESSENGER_NOTIFICATION.POST_INVITE_USER_SUCCESS
            callback(STATUS_RESPONSE.SUCCESS, newMessageSuccessLocal)
          })
          .catch((error) => {
            const newMessageErrorLocal = isChangeRole
              ? MESSENGER_NOTIFICATION.POST_CHANGE_ROLE_ERROR
              : MESSENGER_NOTIFICATION.POST_INVITE_USER_ERROR
            callback(
              STATUS_RESPONSE.ERROR,
              error.response?.data?.message
                ? error.response?.data?.message
                : newMessageErrorLocal
            )
          })
      }
    )
  })
}
export const postCopyLinkProjectMiddleware = (
  projectId: string,
  data: SetRoleInProjectProps,
  _isChangeRole: boolean,
  callback: (type: STATUS_RESPONSE, messenger: string, link?: string) => void
) => {
  const projectEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
  )
  const currentProjectEncryptionKey = projectEncryptionKeys[projectId]
  const baseURL = window.location.origin
  if (!currentProjectEncryptionKey) {
    callback(
      STATUS_RESPONSE.SUCCESS,
      `Copy link successfully!`,
      `${baseURL}/project-detail/${projectId}`
    )
    return
  }

  Axios.post("/api/auth/get-token", {
    email: data.email,
  }).then((response) => {
    const token = response.data.token

    const encryptedKey = encryptionHelper.encrypt(
      token,
      currentProjectEncryptionKey
    )
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string
    // encrypt related conversation keys here
    Axios.get(`/api/auth/project-conversation-keys/${projectId}`).then(
      (response) => {
        const encryptedConversationKeys = response.data.data
        const rawKeys = encryptedConversationKeys.reduce((pre, cur) => {
          return {
            ...pre,
            [cur.id]: !cur.projectId
              ? encryptionHelper.decrypt(userVaultKey, cur.key)
              : encryptionHelper.decrypt(
                  projectEncryptionKeys[cur.projectId],
                  cur.key
                ),
          }
        }, {})

        const encryptedConvoKeys = encryptionHelper.encrypt(
          token,
          JSON.stringify(rawKeys)
        )
        Axios.post(`/api/project/${projectId}/copy-link-encrypted`, {
          ...data,
          encryptedKey,
          encryptedConvoKeys,
          token,
        })
          .then((response: AxiosResponse) => {
            callback(
              STATUS_RESPONSE.SUCCESS,
              `Copy link successfully!`,
              response.data.data.link
            )
          })
          .catch((errorSend) => {
            callback(
              STATUS_RESPONSE.ERROR,
              errorSend.response?.data?.message ?? `Copy link failed!`
            )
          })
      }
    )
  })
}
export const deleteRemoveUserShareInProjectMiddleware = (
  projectId: string,
  idUser: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project/${projectId}/remove/${idUser}`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.SHARE_REMOVE_USER_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.SHARE_REMOVE_USER_ERROR
      )
    })
}

export const getUsersInProject = async (projectId: string) => {
  try {
    const response: AxiosResponse<{
      data: GetUsersInProjectProp[]
    }> = await Axios.get(`/api/project/${projectId}/role-users`)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const postCopyLinkProject = async (projectId: string, role: number) => {
  try {
    const response: AxiosResponse<{
      data: { url: string }
    }> = await Axios.post(`/api/project/${projectId}/copy-link`, {
      role,
    })
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getActivitiesLog = async (
  projectId: string,
  params?: ParamsGetActivitiesLog
) => {
  try {
    const response: AxiosResponse<{
      data: GetActivitiesLogData[]
    }> = await Axios.get(`/api/project/${projectId}/log`, { params })
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

// Comment
export const postBuildComment = async (
  id: string,
  data: PostCommentBodyData
) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-build/${id}/comment`, data)
  return response.data.data
}

export const getBuildComments = async (id: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData[]
  }> = await Axios.get(`/api/project-build/${id}/comment`)
  return response.data.data
}

export const deleteBuildComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.delete(`/api/project-build/comment/${commentId}`)
  return response.data.data
}

export const resolveBuildComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-build/comment/${commentId}/resolve`)
  return response.data.data
}

// Build status
export const getBuildStatusMiddleware = async () => {
  try {
    const response: AxiosResponse<{
      data: BuildStatusProp[]
    }> = await Axios.get(`/api/project-build/statuses`)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const postChangeBuildStatusMiddleware = async (
  buildId: string,
  data: ChangeBuildStatusData
) => {
  try {
    const response: AxiosResponse<{
      data: any
    }> = await Axios.post(`/api/project-build/${buildId}/status`, {
      ...data,
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.ChangeBuildStatus,
        {}
      ),
    })
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getProjectComponentsNotYetBuildMiddleware = async (
  idProjectBuild: string,
  params?: ParamsProjectComponentProps
) => {
  const response: AxiosResponse<{
    data: ProjectComponentDetail[]
  }> = await Axios.get(
    `/api/project-component/get-not-yet-build/${idProjectBuild}`,
    {
      params,
    }
  )
  return response.data.data
}

export const putAddBuildMiddleware = async (
  idProjectBuild: string,
  data: CreateBuildRequest
) => {
  const response: AxiosResponse = await Axios.put(
    `/api/project-build/${idProjectBuild}`,
    data
  )
  return response.data.data
}

export const deleteProjectMiddleware = (
  projectId: string,
  data: SetRoleInProjectProps,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project/${projectId}/set-role`, data)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_INVITE_USER_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_INVITE_USER_ERROR
      )
    })
}

export const deleteProjectComponentAttachment = async (id: string) => {
  const response = await Axios.get(`/api/project-component/${id}/conversations`)
  const conversations = response.data.data
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const commentLogs = conversations.map((conversation: any) => {
    const conversationEncryptionKey =
      conversationEncryptionKeys[conversation.conversation_id]
    return {
      content: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CommentDeleteProjectComponent,
        {
          componentCode: conversation.component_code,
          versionCode: conversation.version,
        },
        conversationEncryptionKey
      ),
      created_by: localStorage.getItem("user_id"),
      model: "conversations",
      relation_id: conversation.conversation_id,
      is_notification: true,
    }
  })
  return Axios.put(`/api/project-component/${id}/delete-attachment`, {
    log: {
      logDeleteComponent: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DeleteComponent,
        {}
      ),
    },
    commentLogs,
  }).then((res) => res.data)
}

export const createFolderMiddleWare = async (
  projectId: string,
  name: string
) => {
  const res = await Axios.post("api/group/create", {
    project_id: projectId,
    name,
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.CreateFolder, {
      folderName: name,
    }),
  })
  return res.data.data
}

export const putFolderMiddleware = async (
  folderId: string,
  name: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  oldName?: string
) => {
  return Axios.put(`api/group/${folderId}/update`, {
    name,
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.UpdateFolder, {
      oldName,
      newName: name,
    }),
  })
    .then(
      (
        _response: AxiosResponse<{
          data: ProjectComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_FOLDER_SUCCESS
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.PUT_FOLDER_ERROR
      )
    })
}
export const moveComponentInOrOutOfAFolder = async (
  folderId: string,
  componentId: string,
  type: "in" | "out",
  componentCode: string,
  folderName: string
) => {
  const response = await Axios.post(`api/group/${folderId}/move`, {
    project_component_id: componentId,
    move: type === "in" ? 1 : 0,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.MoveComponentInFolder,
      { direction: type, componentCode, folderName }
    ),
  })

  return response.data
}

export const deleteFolderMiddleWare = async (
  folderId: string,
  folderName: string
) => {
  const encryptedLog = activityLogHelper.toEncryptedMessage(
    ActivityLogType.DeleteFolder,
    { folderName }
  )
  const response = await Axios.put(`api/group/${folderId}/delete`, {
    log: {
      logDeleteGroup: encryptedLog,
      logDeleteComponent: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DeleteComponent,
        {}
      ),
    },
  })
  return response.data
}
