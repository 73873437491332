import React, { useState, useEffect, useCallback, useRef } from "react"
import PageLayoutAuth from "./PageLayoutAuth"
import InputDefault from "components/Input/InputDefault"
import FormInputCard from "./molecules/FormInputCard"
import Header from "./atoms/Header"
import LabelChangeRouter from "./molecules/LabelChangeRouter"
import RememberMeCard from "./molecules/RememberMeCard"
import { PATH } from "constants/path"
import { LoginRequest, defaultLoginRequest, MFAMethod } from "./types"
import { STATUS_INPUT } from "components/Input/types"
import {
  isValidEmail,
  changeLabelStatusEmail,
  umamiTracking,
} from "helpers/utils"
import { cloneDeep } from "lodash"
import { useArray, useBoolean, useNumber } from "helpers/hooks"
import {
  loginMiddleware,
  resendCodeTwoFactor,
  verifyTwoFactor,
} from "./services/api"
import { pushTo } from "helpers/history"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import Button from "components/Button/Button"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ACTION_RECAPTCHA } from "constants/actionReCaptcha"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import moment from "moment"
import { useLocation } from "react-router-dom"
import { EVENT } from "constants/events"
import { EncryptionKeys } from "controllers/EncryptionController"
import { getConversationKeysMiddleware } from "pages/conversations/conversations.api"
import { encryptionHelper } from "helpers/encryption"
interface LoginFormProps {
  onLogin: (data: LoginRequest) => void
}
interface TwoFactorFormProps {
  oneTimeToken: string
  setOneTimeToken: (data: string) => void
  onVerifySuccess?: () => void
  mfaMethods: number[]
  isResetPassword?: boolean
}
enum LoginFormType {
  Login,
  TwoFactor,
}
const LoginForm = (props: LoginFormProps) => {
  const { onLogin } = props
  const [request, setRequest] = useState<LoginRequest>(defaultLoginRequest)
  const [statusInputEmail, setStatusInputEmail] = useState({
    status: STATUS_INPUT.DEFAULT,
    label: "",
  })
  const checkbox = useBoolean()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async () => {
    if (!executeRecaptcha) {
      toast(
        <LabelNotificationPage
          messenger={MESSENGER_NOTIFICATION.RECAPTCHA_AVAILABLE}
          type="error"
        />
      )
      return
    }
    const token = await executeRecaptcha(ACTION_RECAPTCHA.LOGIN)
    onLogin({
      ...request,
      captcha: token,
    })
    umamiTracking(EVENT.LOGIN)
  }

  const handleChangeInput = (key: "email" | "password") => (event: any) => {
    setRequest({
      ...request,
      [key]: event.target.value,
    })
    if (key === "email") {
      setStatusInputEmail(changeLabelStatusEmail(event.target.value))
    }
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onSubmit()
    }
  }

  const handleDisableButton = () => {
    const newRequest = cloneDeep(request)
    let isDisable = false
    Object.values(newRequest).forEach((el, index) => {
      if (!el) {
        isDisable = true
      } else {
        switch (Object.keys(newRequest)[index]) {
          case "email":
            if (!isValidEmail(el)) {
              isDisable = true
            }
            break
          case "password":
            isDisable = el.length < 1
            break
        }
      }
    })

    return isDisable
  }

  return (
    <div>
      <Header title="Log In to Tracelium" />
      <LabelChangeRouter
        title="Don’t have an account?"
        label="Sign Up"
        path={PATH.signUp}
      />
      <div className="mt-6">
        <FormInputCard title="Email">
          <InputDefault
            type="email"
            value={request.email}
            onChange={handleChangeInput("email")}
            status={statusInputEmail.status}
            labelStatus={statusInputEmail.label}
            onKeyPress={onKeyPress}
          />
        </FormInputCard>
        <FormInputCard title="Password">
          <InputDefault
            type="password"
            isPassword
            value={request.password}
            onChange={handleChangeInput("password")}
            onKeyPress={onKeyPress}
          />
        </FormInputCard>
      </div>
      <div className="mt-3 mb-6 flex items-center justify-between">
        <RememberMeCard
          title="Remember me"
          checked={checkbox.value}
          setChecked={checkbox.setValue}
        />
        <p
          className="font-normal cursor-pointer custom-text-black"
          style={{
            fontSize: 14,
            lineHeight: "24px",
            color: "#7A7A7A",
          }}
          onClick={() => pushTo(PATH.forget)}
        >
          Forgot Password?
        </p>
      </div>
      <Button
        title="Log in"
        onClick={onSubmit}
        isDisabledBtn
        widthBtn="100%"
        disabled={handleDisableButton()}
      />
    </div>
  )
}

export const TwoFactorForm = (props: TwoFactorFormProps) => {
  const { oneTimeToken, setOneTimeToken } = props
  const location = useLocation<any>()
  const [timer, setTimer] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const [input, setInput] = useState({
    status: STATUS_INPUT.ERROR,
    label: "Verification code required",
    value: "",
  })
  const currentMFAMethod = useNumber(MFAMethod.authenticatorApp)
  const [hasResentCode, setHasResentCode] = useState(false)

  useEffect(() => {
    props.mfaMethods.includes(MFAMethod.authenticatorApp)
      ? currentMFAMethod.setValue(MFAMethod.authenticatorApp)
      : currentMFAMethod.setValue(MFAMethod.email)
  }, [JSON.stringify(props.mfaMethods)])

  const onSubmit = async () => {
    if (props.isResetPassword) {
      verifyTwoFactor(
        input.value,
        oneTimeToken,
        currentMFAMethod.value,
        props.isResetPassword
      )
        .then(() => {
          if (props.onVerifySuccess) {
            props.onVerifySuccess()
          }
        })
        .catch((error) => {
          const message =
            error.response?.data?.message ?? "Something went wrong!"
          toast(<LabelNotificationPage messenger={message} type="error" />)
        })
    } else {
      verifyTwoFactor(
        input.value,
        oneTimeToken,
        currentMFAMethod.value,
        props.isResetPassword
      )
        .then((response) => {
          localStorage.setItem("access_token", response.token)
          if (location.state?.redirectUrl) {
            pushTo(location.state.redirectUrl)
          } else {
            pushTo(PATH.projects)
          }
        })
        .catch((error) => {
          const message =
            error.response?.data?.message ?? "Something went wrong!"
          toast(<LabelNotificationPage messenger={message} type="error" />)
        })
    }
  }

  const handleChangeInput = (event: any) => {
    const value = event.target.value
    let status = STATUS_INPUT.DEFAULT
    let label = ""
    if (value.length < 6) {
      label = "Verification code must be a 6 digit number"
      status = STATUS_INPUT.ERROR
    }
    if (value.length === 0) {
      label = "Verification code required"
    }
    setInput({ value, status, label })
  }
  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (handleDisableButton()) {
        return
      }
      onSubmit()
    }
  }

  const handleDisableButton = () => {
    /// 6-digit code
    if (input.value.length !== 6) {
      return true
    }
    return false
  }

  const setResendTime = useCallback((resendTime: moment.Moment) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      const diff = resendTime.diff(moment(), "seconds")
      if (diff <= 0) {
        clearInterval(intervalRef.current as any)
        intervalRef.current = null
        setHasResentCode(false)
        return
      }
      setTimer(diff)
    }, 1000)
  }, [])

  useEffect(() => {
    setResendTime(moment().add(30, "seconds"))

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [setResendTime])

  const onResendCode = () => {
    if (hasResentCode) return
    resendCodeTwoFactor(oneTimeToken)
      .then((response) => {
        setOneTimeToken(response.token)
        setResendTime(moment().add(30, "seconds"))
        setHasResentCode(true)
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? "Something went wrong!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }
  const renderMFAByEmailForm = () => {
    return (
      <div className="relative">
        <Header title="Two-factor authentication" />
        <p className="mt-6 text-black">
          Get a verification code from your email.
        </p>
        <div className="mt-6">
          <FormInputCard title="Verification code">
            <InputDefault
              type="text"
              value={input.value}
              onChange={handleChangeInput}
              status={input.status}
              labelStatus={input.label}
              onKeyPress={onKeyPress}
              placeholder="Enter Verification code"
              pattern="\d+"
            />
          </FormInputCard>
        </div>
        <div>
          <p className="text-xs text-black">
            Didn't get the verification code?{" "}
            <span
              className={
                timer > 0
                  ? `text-gray cursor-not-allowed`
                  : `text-blue cursor-pointer`
              }
              onClick={onResendCode}
            >
              Resend {timer > 0 ? `(${timer})` : ""}
            </span>
          </p>
        </div>
        {props.mfaMethods.length > 1 ? (
          <p className={"text-xs pt-4"}>
            <span
              className={` text-blue cursor-pointer`}
              onClick={() => {
                currentMFAMethod.setValue(MFAMethod.authenticatorApp)
              }}
            >
              Use the authenticator app instead.
            </span>
          </p>
        ) : null}
        <Button
          title="Verify Code"
          onClick={onSubmit}
          isDisabledBtn
          widthBtn="100%"
          disabled={handleDisableButton()}
          className="mt-6"
        />
      </div>
    )
  }
  const renderMFAByAuthenticatorAppForm = () => {
    return (
      <div className="relative">
        <Header title="Two-factor authentication" />
        <p className="mt-6 text-black">
          Get a verification code from the Authenticator app.
        </p>
        <div className="mt-6">
          <FormInputCard title="Verification code">
            <InputDefault
              type="text"
              value={input.value}
              onChange={handleChangeInput}
              status={input.status}
              labelStatus={input.label}
              onKeyPress={onKeyPress}
              placeholder="Enter Verification code"
              pattern="\d+"
            />
          </FormInputCard>
        </div>

        {props.mfaMethods.length > 1 ? (
          <p className={"text-xs pt-4"}>
            <span
              className={` text-blue cursor-pointer`}
              onClick={() => {
                currentMFAMethod.setValue(MFAMethod.email)
                onResendCode()
              }}
            >
              Use the email OTP instead.
            </span>
          </p>
        ) : null}
        <Button
          title="Verify Code"
          onClick={onSubmit}
          isDisabledBtn
          widthBtn="100%"
          disabled={handleDisableButton()}
          className="mt-6"
        />
      </div>
    )
  }
  return currentMFAMethod.value === MFAMethod.email
    ? renderMFAByEmailForm()
    : renderMFAByAuthenticatorAppForm()
}
const setConversationKeys = async () => {
  //get related conversation keys
  const vaultKey = localStorage.getItem(EncryptionKeys.userVaultKey)
  const conversationKeys = await getConversationKeysMiddleware()
  if (vaultKey) {
    const result = conversationKeys.reduce((pre, item) => {
      return {
        ...pre,
        [item.conversationId]: encryptionHelper.decrypt(
          vaultKey,
          item.encryptedKey
        ),
      }
    }, {})
    localStorage.setItem(
      EncryptionKeys.conversationEncryptionKeys,
      JSON.stringify(result)
    )
  }
}
const Login = () => {
  const [formType, setFormType] = useState<LoginFormType>(LoginFormType.Login)
  const [oneTimeToken, setOneTimeToken] = useState<string>("")
  const location = useLocation<any>()
  const mfaMethods = useArray([])
  const onLogin = async (loginData: LoginRequest) => {
    //re
    loginMiddleware(loginData)
      .then(async (response) => {
        if (!response.type) {
          localStorage.setItem("access_token", response.token)
          await setConversationKeys()
          if (location.state?.redirectUrl) {
            pushTo(
              location.state.redirectUrl,
              undefined,
              undefined,
              location.state.search
            )
          } else {
            pushTo(PATH.projects)
          }
        } else {
          setOneTimeToken(response.token)
          setFormType(LoginFormType.TwoFactor)
          mfaMethods.setValue(response.mfaMethods)
        }
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ??
          "Please enter a correct email and password. Note that both fields may be case-sensitive!"
        toast(<LabelNotificationPage messenger={message} type="error" />)
      })
  }
  return (
    <PageLayoutAuth showPrivacy>
      <div className="h-full w-full flex flex-col p-6 relative">
        {formType === LoginFormType.Login ? (
          <LoginForm onLogin={onLogin} />
        ) : (
          <TwoFactorForm
            oneTimeToken={oneTimeToken}
            setOneTimeToken={setOneTimeToken}
            mfaMethods={mfaMethods.value}
          />
        )}
      </div>
    </PageLayoutAuth>
  )
}
export default Login
