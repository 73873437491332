import { useBoolean, useString, useWindowSize } from "helpers/hooks"
import {
  base64ToFile,
  fileArrayToFileList,
  formatDate,
  processImageFiles,
  removeImageBySrc,
} from "helpers/utils"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
// import { Note } from "../../conversations.type"
import { ReactComponent as TimestampIcon } from "assets/images/icons/icon-editor-timestamp.svg"
import { ReactComponent as EditorFileIcon } from "assets/images/icons/icon-editor-file.svg"
import { ReactComponent as EditorTextBoldIcon } from "assets/images/icons/icon-editor-bold.svg"
import { ReactComponent as EditorTextItalicIcon } from "assets/images/icons/icon-editor-italic.svg"
import { ReactComponent as EditorTextColorIcon } from "assets/images/icons/icon-editor-text-color.svg"
import { ReactComponent as EditorTextBulletListIcon } from "assets/images/icons/icon-editor-bullet-list.svg"
import { ReactComponent as EditorTextOrderListIcon } from "assets/images/icons/icon-editor-ordered-list.svg"
import { ReactComponent as EditorImageIcon } from "assets/images/icons/icon-editor-image.svg"

import FileEditorCard from "pages/project-component-detail/molecules/FileEditorCard"
import ImageResize from "quill-image-resize-module-react"
import Tippy from "@tippyjs/react"
import ReactDOMServer from "react-dom/server"
import { FileEditorDetail } from "pages/project-component-detail/types"
import Button from "components/Button/Button"
import { TippyCustomzie } from "components/TippyCustomzie"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import ActionItem from "pages/projects/molecules/ActionItem"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import DraftToHtml from "draftjs-to-html"
import { toast } from "react-toastify"
import Delayed from "components/Delayed"
import DropOverlay from "assets/images/drop-overlay.png"
const icons = Quill.import("ui/icons")

window.Quill = Quill
Quill.register("modules/imageResize", ImageResize)
const MAX_FILE_SIZE = 2 * 1000 * 1000 // 2MB

interface Props {
  value: string
  setValue: (value: string) => void
  handleDeleteFileDownload?: (index: number, idFile?: string) => () => void
  listFileUrl?: FileEditorDetail[]
  handleAddFiles: (
    files: FileList,
    type?: "inline" | "outline",
    imageUrl?: string,
    fileInLine?: FileList,
    fileInLineUrl?: { name: string; imageUrl: string }[]
  ) => void
  listFileAdd: FileEditorDetail[]
  keyInputFile: string
  idPart?: string
  handleDeleteFileNoDownload?: (oldIndex: number) => () => void
  readOnly?: boolean
  styleFileEditorCard?: React.CSSProperties
  styleRootListFile?: React.CSSProperties
  styleIconAddFIle?: React.CSSProperties
  styleRootCard?: React.CSSProperties
  onSubmitButton?: (dataEditor: string) => void
  isDisableButton?: boolean
  styleListFile?: React.CSSProperties
  isBlur?: boolean
  conversationId?: string
  versionId?: string
  isDropImage?: boolean
}

const CustomToolbar = ({ id }: { id: string }) => {
  return (
    <div id={id} className={`toolbar flex gap-1 text-editor ${id}`}>
      <div className="">
        <select
          className="ql-header"
          defaultValue={""}
          onChange={(e) => e.persist()}
        >
          <option value="1" />
          <option value="2" />
          <option selected />
        </select>
      </div>
      <div className="h-[20px] bg-stroke  w-[0.5px]"></div>

      <Tippy placement="top" content={"Bold"} offset={[0, 15]}>
        <button className="ql-bold" />
      </Tippy>
      <Tippy placement="top" content={"Italic"} offset={[0, 15]}>
        <button className="ql-italic" />
      </Tippy>
      <Tippy placement="top" content={"Text color"} offset={[0, 15]}>
        <div>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>
        </div>
      </Tippy>
      <Tippy
        placement="top"
        className=""
        content={"Ordered list"}
        offset={[0, 15]}
      >
        <button className=" ql-list" value={"ordered"} />
      </Tippy>
      <Tippy
        placement="top"
        className=""
        content={"Bulleted list"}
        offset={[0, 15]}
      >
        <button className=" ql-list" value={"bullet"} />
      </Tippy>
    </div>
  )
}

const InputEditor2 = (props: Props) => {
  const [containerQuill] = useState(
    `quill${Math.random().toString(36).slice(2)}`
  )
  const {
    setValue,
    listFileUrl,
    handleDeleteFileDownload,
    handleAddFiles,
    listFileAdd,
    keyInputFile,
    handleDeleteFileNoDownload,
    idPart,
    readOnly = false,
    onSubmitButton,
    isDisableButton,
    conversationId,
    versionId,
    isDropImage = true,
  } = props
  let value = props.value
  try {
    const draftContent = JSON.parse(value)
    if (draftContent) {
      value = DraftToHtml(draftContent)
    }
  } catch (error) {}
  const quillRef = useRef<any>(`${versionId}-${conversationId}`)
  const editorValue = useString(value)
  const isFocus = useBoolean()
  const { isMobile } = useWindowSize()
  const showTippy = useBoolean()
  const [dropOverlay, setDropOverlay] = useState<File | null>(null)

  const isEnableSubmitButton = editorValue.value || listFileAdd.length

  const dragCounter = useRef(0) // Counter to track drag depth
  const dragURLImage = useRef("")

  useEffect(() => {
    editorValue.setValue(value)
  }, [value])

  useEffect(() => {
    if (dropOverlay) {
      return
    }
    getDropOverlayImage()
  }, [dropOverlay])

  const getDropOverlayImage = async () => {
    const file = await base64ToFile(
      DropOverlay,
      "drop-overlay.png",
      "image/png"
    )
    setDropOverlay(file)
  }

  const imageHandler = async () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()
    input.onchange = async function () {
      const file = input && input.files ? input.files[0] : null
      const files = input && input.files ? input.files : null
      if (!file) return
      if (!files) return
      if (!isFileSizeValid(file)) {
        return toast(
          <LabelNotificationPage
            type="warning"
            messenger="Image have to be smaller than 2Mb."
          />
        )
      }
      const reader = new FileReader()
      reader.onload = function (e) {
        if (!e.target?.result) return
        const quill = quillRef?.current?.getEditor()
        const cursorPosition = quill.getSelection()?.index || 0
        const blob = new Blob([e.target?.result], { type: file.type })
        const imageUrl = URL.createObjectURL(blob)
        handleAddFiles(files, "inline", imageUrl)
        quill.insertEmbed(cursorPosition, "image", imageUrl)
        quill.setSelection(cursorPosition + 1)
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const onChangeEditor = (newValue: string) => {
    // the url in image tag got encoded from & -> &amp so we need to replace it back to apply our logic
    // const editedNote = notes.replaceAll("&amp;", "&")
    // newValue === "<p><br></p>" ||
    if (
      newValue?.trim().replace(/[\r\n]/gm, "") ===
      value?.trim().replace(/[\r\n]/gm, "")
    ) {
      return
    }
    if (newValue !== editorValue.value) {
      editorValue.setValue(newValue)
      setValue(newValue)
    }
  }
  const onDeleteFileNoDownload =
    (oldIndex: number, file: FileEditorDetail) => () => {
      if (handleDeleteFileNoDownload) {
        if (file.type === "inline") {
          const updatedEditorValue = removeImageBySrc(
            editorValue.value,
            file.url || ""
          )
          onChangeEditor(updatedEditorValue)
          isFocus.setValue(true)
        }
        handleDeleteFileNoDownload(oldIndex)()
      }
    }
  const insertFiles = async () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "*")
    input.setAttribute("multiple", "true")
    input.click()
    input.onchange = async function (event) {
      const files = input ? input.files : null
      if (!files) return
      handleAddFiles(files)
    }
  }
  const insertTimestamp = () => {
    const currentTimestamp = formatDate(new Date())
    const quill = quillRef?.current?.getEditor()
    const cursorPosition = quill.getSelection()?.index || 0
    const oldFormat = quill?.getFormat()
    quill.insertText(cursorPosition, currentTimestamp, { color: "#FF8C00" })
    quill.setSelection(cursorPosition + currentTimestamp.length)
    quill.format("color", "black")
    for (const key in oldFormat) {
      quill.format(key, oldFormat[key])
    }
  }

  const modules = useMemo(() => {
    icons.bold = ReactDOMServer.renderToString(<EditorTextBoldIcon />)
    icons.italic = ReactDOMServer.renderToString(<EditorTextItalicIcon />)
    icons.color = ReactDOMServer.renderToString(<EditorTextColorIcon />)
    icons.list.bullet = ReactDOMServer.renderToString(
      <EditorTextBulletListIcon />
    )
    icons.list.ordered = ReactDOMServer.renderToString(
      <EditorTextOrderListIcon />
    )
    var Image = Quill.import("formats/image")
    Image.sanitize = function (url) {
      return url
    }
    return {
      toolbar: {
        container: `#${containerQuill}`,
        handlers: {
          image: imageHandler,
          insertTimestamp,
          insertFiles,
        },
      },
      clipboard: {
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "Toolbar"],
      },
    }
  }, [containerQuill])

  const handleDrop = async (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    removeDropOverlayImage()
    dragCounter.current = 0
    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      if (event.dataTransfer.files[0].size > 50 * 1000 * 1000)
        return toast(
          <LabelNotificationPage
            messenger={`The upload of file larger than ${50}MB is not allowed`}
            type="error"
          />
        )
    }
    if (!isDropImage) {
      handleAddFiles(event.dataTransfer.files)
      return
    }
    const files = Array.from(event.dataTransfer.files)
    const imageFiles = files.filter(
      (file) => file.type.startsWith("image/") && isFileSizeValid(file)
    )
    const otherFiles = files.filter((file) => !file.type.startsWith("image/"))

    const imageFileNotValid = files.filter(
      (el) => el.type.startsWith("image/") && !isFileSizeValid(el)
    )
    const newURLFiles = await processImageFiles(imageFiles)

    if (files.length) {
      handleAddFiles(
        fileArrayToFileList(otherFiles.concat(imageFileNotValid)),
        undefined,
        undefined,
        fileArrayToFileList(imageFiles),
        newURLFiles
      )
    }
    if (newURLFiles.length) {
      handleImageFileInput(newURLFiles)
    }
    if (imageFileNotValid.length) {
      imageFileNotValid.forEach((file) => {
        showFileSizeWarning(file.name)
      })
    }
  }

  const handleImageFileInput = (
    files: {
      name: string
      imageUrl: string
    }[]
  ) => {
    const quill = quillRef?.current?.getEditor()
    for (const file of files) {
      insertImageToEditor(file, quill)
    }
  }

  const isFileSizeValid = (file: File): boolean => {
    return file.size <= MAX_FILE_SIZE
  }

  const showFileSizeWarning = (fileName: string) => {
    toast(
      <LabelNotificationPage
        type="warning"
        messenger={`Image ${fileName} have to be smaller than 2Mb.`}
      />
    )
  }

  const insertImageToEditor = (
    file: {
      name: string
      imageUrl: string
    },
    quill: any
  ) => {
    const cursorPosition = quill.getSelection()?.index || 0
    quill.insertEmbed(cursorPosition, "image", file.imageUrl)
    quill.setSelection(cursorPosition + 1)
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    if (!isDropImage) {
      return
    }
    dragCounter.current += 1
    if (dragCounter.current === 1) {
      if (!dropOverlay || dragURLImage.current) return
      const quill = quillRef?.current?.getEditor()
      const delta = quill.getContents()
      const dropOverlayCurrent = delta.ops?.findIndex(
        (op) =>
          op.insert && String(op.insert?.image) === String(dragURLImage.current)
      )
      if (dropOverlayCurrent > -1) {
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        if (!e.target?.result) return
        // Track the inserted image

        const blob = new Blob([e.target.result], { type: dropOverlay.type })
        const imageUrl = URL.createObjectURL(blob)
        dragURLImage.current = imageUrl
        const cursorPosition = quill.getSelection()?.index || 0
        quill.insertEmbed(cursorPosition, "image", imageUrl)
        quill.setSelection(cursorPosition + 1)
      }
      reader.readAsArrayBuffer(dropOverlay)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    if (!isDropImage) {
      return
    }
    dragCounter.current -= 1
    if (dragCounter.current === 0) {
      removeDropOverlayImage()
    }
  }

  const removeDropOverlayImage = () => {
    const quill = quillRef?.current?.getEditor()
    const delta = quill.getContents()
    let imageIndex = -1

    // Loop through contents to find the image index
    delta.ops.forEach((op, index) => {
      if (op.insert && op.insert.image === dragURLImage.current) {
        imageIndex = delta.ops
          .slice(0, index)
          .reduce(
            (acc, cur) =>
              acc + (typeof cur.insert === "string" ? cur.insert.length : 1),
            0
          )
      }
    })

    // If image is found, delete it
    if (imageIndex !== -1) {
      quill.deleteText(imageIndex, 1) // Delete 1 character (image)
    }
    dragURLImage.current = ""
  }

  return (
    <div className="text-editor relative w-full flex flex-col gap-1 ">
      <div
        className={`${
          readOnly ? "hidden" : "flex"
        } items-center pr-2 rounded-full bg-black-04 w-fit `}
      >
        <CustomToolbar id={containerQuill} />
        {/* desktop */}
        <div className="hidden md:block">
          {!idPart && (
            <Tippy placement="top" content={"Image"} offset={[0, 15]}>
              <button
                className="ql-image text-editor p-1 hover:bg-[#7a7a7a] rounded-[6px]"
                onClick={imageHandler}
              >
                <EditorImageIcon />
              </button>
            </Tippy>
          )}

          <Tippy placement="top" content={"Add timestamp"} offset={[0, 15]}>
            <button
              onClick={insertTimestamp}
              className="ql-insertTimestamp text-editor p-1 hover:bg-[#7a7a7a] rounded-[6px]"
            >
              <TimestampIcon />
            </button>
          </Tippy>
          <Tippy placement="top" content={"Files"} offset={[0, 15]}>
            <button
              className="text-editor p-[2px] hover:bg-[#7a7a7a] rounded-[6px]"
              onClick={insertFiles}
            >
              <EditorFileIcon />
            </button>
          </Tippy>
        </div>

        {/* mobile */}
        <TippyCustomzie
          containerClass="md:hidden component-card-menu reset-tippy"
          placement="top-end"
          interactive
          arrow={false}
          animation="scale"
          visible={showTippy.value}
          onClickOutside={() => showTippy.setValue(false)}
          content={
            <div
              style={{
                background: "#222222",
                borderRadius: 4,
                paddingTop: 8,
                paddingBottom: 8,
                width: 165,
              }}
            >
              {!idPart && (
                <ActionItem
                  title={"Insert image"}
                  onClick={imageHandler}
                  icon={<EditorImageIcon />}
                />
              )}

              <ActionItem
                title={"Insert files"}
                onClick={insertFiles}
                icon={<EditorFileIcon />}
              />
              <ActionItem
                title={"Insert timestamp"}
                onClick={insertTimestamp}
                icon={<TimestampIcon />}
              />
            </div>
          }
          allowHTML
        >
          <div
            className="flex items-center"
            onClick={() => {
              showTippy.setValue(true)
            }}
          >
            <ActionIconHistory
              icon="three-dots"
              tooltip="Action"
              styleIcon={{
                marginRight: 0,
              }}
            />
          </div>
        </TippyCustomzie>
      </div>

      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Delayed waitBeforeShow={200}>
          <ReactQuill
            key={`${versionId}/${conversationId}/editor/${containerQuill}`}
            className={`w-full px-2 text-black leading-3 overflow-auto rounded-4 border-[1.5px] border-gray-300 rich-editor ${
              isFocus.value ? "active-project-build" : ""
            }`}
            ref={quillRef}
            value={editorValue.value}
            onChange={onChangeEditor}
            onFocus={() => isFocus.setValue(true)}
            onBlur={() => isFocus.setValue(false)}
            modules={modules}
            readOnly={readOnly}
            theme="snow"
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              // "imagewithstyle",
              "code",
              "color",
              "background",
              "code-block",
              "align",
              "width",
            ]}
          />
        </Delayed>
      </div>
      <div className=" flex justify-center gap-2 rounded-2 bottom-[-10px] absolute w-full px-3 pb-1 ">
        <div className="flex items-center ml-3  max-h-[60px] overflow-y-auto w-full flex-wrap">
          {listFileUrl && listFileUrl.length && handleDeleteFileDownload
            ? listFileUrl.map((file, index) => (
                <FileEditorCard
                  key={index}
                  index={index}
                  name={file.name}
                  isDownload={true}
                  isDeleted={!readOnly}
                  idFile={file.id}
                  handleDeleteFile={handleDeleteFileDownload}
                  urlFile={file.file}
                  conversationId={conversationId}
                  versionId={versionId}
                />
              ))
            : null}
          {listFileAdd &&
            listFileAdd.length !== 0 &&
            handleDeleteFileNoDownload &&
            listFileAdd.map((file, index) => (
              <FileEditorCard
                key={`${index}${file.name}`}
                index={index}
                name={file.name}
                isDownload={false}
                isDeleted={true}
                urlFile={file.file}
                handleDeleteFile={() => onDeleteFileNoDownload(index, file)}
                conversationId={conversationId}
              />
            ))}
        </div>
        {onSubmitButton ? (
          <div className="my-3 pl-3 ">
            <Button
              title="Submit"
              onClick={() => {
                onSubmitButton(editorValue.value)
              }}
              disabled={!isEnableSubmitButton || readOnly || isDisableButton}
              isDisabledBtn
              sizeBtn="small"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default InputEditor2
