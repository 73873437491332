/* eslint-disable react-hooks/exhaustive-deps */
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { customLocalStorageHandler } from "helpers/utils"
import { dataEditorDefault } from "pages/project-component-detail/types"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  ConversationMessagesDetailItem,
  ConversationMessagesDetail,
} from "../conversations.type"
import MessagesConversationItemCard from "../molecules/MessagesConversationItemCard"
import { postConversationSendMessageMiddleware } from "../conversations.api"
import { useAppSelector } from "hooks/useApp"
import { useBoolean } from "helpers/hooks"
import FormInputEditorCard from "pages/project-build/molecules/FormInputEditorCard"
import { useAdditionalAndExtraInfoProjectBuild } from "pages/project-build/project-build.hook"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { ReactComponent as IconMessagerDeleteComponent } from "assets/images/icons/icon-messager-delete-component.svg"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { v4 as uuid } from "uuid"
import { isEmpty } from "lodash"
import { configureStore } from "stores/configureStore"
import { startLoading } from "reducers/loading"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"

interface Props {
  originMessages: ConversationMessagesDetailItem[]
  idConversation: string
  isEditorInput: boolean
  inviteeUser: ConversationMessagesDetail["invitee"]
  isChangeScrollMessageBottom: boolean
  updateWhenAddMessager: (message: string) => void
  onScrollMessage: () => void
  isDeleted?: boolean
  notYetCommented: boolean
}
const TabCoversationDetail = (props: Props) => {
  const {
    originMessages,
    isEditorInput,
    idConversation,
    inviteeUser,
    isChangeScrollMessageBottom,
    onScrollMessage,
    updateWhenAddMessager,
    isDeleted,
    notYetCommented,
  } = props
  const userInfo = useAppSelector((state) => state.userInfo)
  const openWarningModal = useBoolean(false)
  let { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.CONVERSATION_TEXT_INPUT
  )
  const {
    dataEditor,
    isLoading,
    keyInputFile,
    ref,
    listFileAdd,
    setDataEditor,
    fileSelectedHandler,
    handleChangeEditor,
    handleDeleteFileNoDownload,
    setListFileAdd,
    handleChangeScroll,
    clearStorageWhenSubmit,
  } = useAdditionalAndExtraInfoProjectBuild(
    "tab_conversation_detail",
    idConversation
  )

  const { workerUploadFile } = useUploadFileWorkerController()

  useEffect(() => {
    if (!isChangeScrollMessageBottom) {
      return
    }
    handleChangeScroll()
  }, [JSON.stringify(originMessages), isChangeScrollMessageBottom])

  const onWarningSubmit = (dataEditor: string) => {
    if (
      !notYetCommented ||
      !inviteeUser.email ||
      inviteeUser.email === userInfo.email ||
      inviteeUser.is_sent
    ) {
      onSubmitButton(dataEditor)
      return
    }
    openWarningModal.setValue(true)
    setDataEditor(dataEditor)
  }

  const onSubmitButton = async (dataEditor: string) => {
    if (!idConversation || !workerUploadFile) {
      toast(
        <LabelNotificationPage
          messenger="The workerUploadFile is not working!"
          type="error"
        />
      )
      return
    }

    openWarningModal.setValue(false)

    isLoading.setValue(true)
    let newDataEditor = dataEditor
    listFileAdd
      .filter((item) => item.type === "inline")
      .forEach((item) => {
        newDataEditor = newDataEditor.replace(item.url || "", item.id)
      })

    configureStore.dispatch(startLoading())

    postConversationSendMessageMiddleware(
      workerUploadFile,
      idConversation,
      {
        content: isEmpty(newDataEditor) ? dataEditorDefault : newDataEditor,
        files: listFileAdd.map((file) => {
          return {
            file: file.file,
            file_name: file.name,
            key: file.id ?? uuid(),
            type: file.type,
            file_type: file.file_type,
          }
        }),
      },
      (type: STATUS_RESPONSE, messenger: string) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          setListFileAdd([])
          setDataEditor("")
          clearStorageWhenSubmit()
          updateWhenAddMessager(dataEditor)
        } else {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
        }
      },
      inviteeUser
    )
  }

  const onDisableButtonSubmit = () => {
    let value = true
    if (dataEditor !== dataEditorDefault || listFileAdd.length) {
      value = false
    }
    return value
  }
  const handleScrollMessage = () => {
    const scrollTop = ref.current?.scrollTop
    if (Number(scrollTop) < 1) {
      onScrollMessage()
      ref.current?.scrollTo(0, 1)
    }
  }
  const onchangeEditor = async (value: string) => {
    await handleChangeEditor(value)
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div
        className="flex flex-col flex-1 h-full overflow-auto"
        onScroll={handleScrollMessage}
        ref={ref}
      >
        <div className="pr-0 md:pr-6 h-full">
          {originMessages.length
            ? originMessages.map((messages, index) => (
                <MessagesConversationItemCard
                  idUserInfo={userInfo.id}
                  idInviteeUser={inviteeUser.user_id}
                  messages={messages}
                  key={index}
                  styleRoot={{
                    marginBottom: index === originMessages.length - 1 ? 0 : 12,
                  }}
                  isActiveConversation={isEditorInput && !isDeleted}
                  conversationId={idConversation}
                />
              ))
            : null}
        </div>
      </div>
      {!inviteeUser.is_sent ? (
        <div className="pr-0 md:pr-6 mt-3">
          <div
            className="flex items-center px-2 mb-3 last:mb-0"
            style={{
              minHeight: 38,
              background: "rgba(232, 122, 22, 0.20)",
              borderRadius: 6,
            }}
          >
            <IconMessagerDeleteComponent className="icon-warning-messager" />
            <p
              className="font-normal text-13 leading-22  pl-1"
              style={{
                color: "#E87A16",
              }}
            >
              Warning: Invitation was not sent.
            </p>
          </div>
        </div>
      ) : null}

      {!isDeleted && isEditorInput ? (
        <FormInputEditorCard
          handleChangeEditor={onchangeEditor}
          dataEditor={dataEditor}
          fileSelectedHandler={fileSelectedHandler}
          listFileAdd={listFileAdd}
          keyInputFile={keyInputFile.value}
          handleDeleteFileNoDownload={handleDeleteFileNoDownload}
          onSubmitButton={onWarningSubmit}
          onDisableButtonSubmit={onDisableButtonSubmit()}
          id={idConversation}
        />
      ) : null}
      {openWarningModal.value && (
        <ModalDelete
          onCloseModal={() => openWarningModal.setValue(false)}
          title={`You are about to send a message and invitation to the invitee, which will appear in their email inbox. Please ensure the invitee's email is accurate.`}
          content="This pop-up window will not reappear once you press Proceed."
          label={`Warning`}
          onSubmit={() => onSubmitButton(dataEditor)}
          titleButton="Proceed"
          styleTitle={{
            textAlign: "center",
            // width: 360,
            marginBottom: 10,
          }}
          contentWrapperStyles={{
            paddingTop: 10,
            paddingBottom: 36,
            textAlign: "center",
          }}
        />
      )}
    </div>
  )
}
export default TabCoversationDetail
