import Axios, { AxiosResponse } from "axios"
import {
  BuildHistoryDetail,
  ParamsBuildHistoryProps,
  CreateBuildHistoryRequest,
  CreatePCBRequest,
  PCBDetail,
  LayerAsignmentDetail,
  PutLayerAssignmentRequest,
  SpecificationHistoryRequest,
  PCBVersionSpecification,
  PostCommentBodyData,
  GetTabContentMiddlewareData,
  TabType,
  TypeSelectProp,
  BOMDetail,
  SelectCurrencyDetail,
  DataErrorWhenEditHistoryTree,
  TableColumnBOMDetail,
  UserWorkingItem,
  ParamUserWorkingItem,
  ComponentType,
  SortLayerAssignmentRequest,
} from "../types"
import { STATUS_RESPONSE } from "types"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { ProjectBuildDetail } from "pages/project-component/types"
import { GetCommentsData } from "components/comment/types"
import { encryptionController } from "controllers/EncryptionController"
import _ from "lodash"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { handleBomResponse } from "helpers/bom"
import { validateEncryptedFile } from "helpers/utils"
import { encryptionHelper } from "helpers/encryption"
import {
  toSpecificationHtml,
  validateSpecificationInput,
} from "helpers/specification.helper"
import { ACTION_WORKER } from "workers/type"
import { getWorkerLocalStorageData } from "workers/utils"

export const getBuildHistoriesMiddleware = async (
  idProjectComponent: string,
  urlHistory: string
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-${urlHistory}/${idProjectComponent}/histories`
  )
  try {
    const result = await Promise.all(
      response.data.data.map(async (version) => {
        const rawCommit = await encryptionController().decrypt(
          version.commit || "",
          { dataType: "string", type: "project" }
        )

        return {
          ...version,
          commit: [rawCommit, version.description]
            .filter((item) => !_.isEmpty(item))
            .join(" - "),
        }
      })
    )

    return result
  } catch (error) {
    return response.data.data
  }
}
export const getProjectComponentHistoryMiddleware = async (
  idProjectComponent: string,
  idConversation?: string
) => {
  let url = `/api/project-component/history/${idProjectComponent}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: BuildHistoryDetail
  }> = await Axios.get(url)
  return response.data.data
}

export const getBuildComponentHistoriesMiddleware = async (
  idProjectBuildComponent: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-build-component/${idProjectBuildComponent}/histories`,
    {
      params,
    }
  )
  return response.data.data
}

export const postBuildHistoryMiddleware = (
  request: CreateBuildHistoryRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: BuildHistoryDetail
  ) => void
) => {
  Axios.post(`/api/project-component/history`, request)
    .then(
      (
        response: AxiosResponse<{
          data: BuildHistoryDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_ERROR
      )
    })
}

export const postPCBMiddleware = (
  projectComponentHistoryId: string,
  request: CreatePCBRequest,
  options: any,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/pcb/upload/${projectComponentHistoryId}`, request, {
    headers: {},
    ...options,
  })
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, MESSENGER_NOTIFICATION.POST_PCB_SUCCESS)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_PCB_ERROR
      )
    })
}

export const addEncryptedPcbFileMiddleware = (
  worker: Worker,
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type: "gerber",
    action: "uploaded",
    fileName: (request.get("file") as any).name,
  })
  request.append("log", log)

  Axios.post(`/api/pcb/to-svg`, request)
    .then(async (response: AxiosResponse) => {
      const file = request.get("file") as File
      request.delete("file")
      worker.postMessage({
        action: ACTION_WORKER.START,
        payload: {
          isRawFile: true,
          files: [file],
          localStorageData: getWorkerLocalStorageData(),
          fileOption: {
            dataType: "file",
            type: "project",
          },
          dataSvgs: response.data.data,
        },
      })
      worker.onmessage = async function (e) {
        if (e.data.action === ACTION_WORKER.FINISH) {
          const { fileHashs, attachments, svgs, svgRequest } = e.data.payload
          const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
          attachments.forEach((attachment) => {
            request.append("file", attachment.blob, attachment.fileName)
          })

          svgRequest.forEach((elSvg) => {
            request.append("svg", elSvg.blob, elSvg.fileName)
          })

          request.append("file_hash", fileHash)
          request.append("layers", JSON.stringify(svgs))

          Axios.post(
            `/api/pcb/upload-gerber/${projectComponentHistoryId}`,
            request
          )
            .then(async (response: AxiosResponse) => {
              await validateEncryptedFile(
                fileHash,
                response.data.data.file,
                response.data.data.project_id
              )
              callback(
                STATUS_RESPONSE.SUCCESS,
                MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
              )
            })
            .catch((error) => {
              callback(
                STATUS_RESPONSE.ERROR,
                error.response?.status === 413
                  ? `The upload of file larger than 50MB is not allowed`
                  : error.response?.data?.message ??
                      MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
              )
            })
        }
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
export const addPcbFileMiddleware = (
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type: "gerber",
    action: "uploaded",
    fileName: (request.get("file") as any).name,
  })
  request.append("log", log)
  Axios.post(`/api/pcb/upload/${projectComponentHistoryId}`, request)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.ADD_PCB_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.status === 413
          ? `The upload of file larger than 50MB is not allowed`
          : error.response?.data?.message ??
              MESSENGER_NOTIFICATION.ADD_PCB_FILE_ERROR
      )
    })
}
export const uploadBOMFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })

      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadSubBom,
        {
          type: "pcb",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)

      Axios.post(`/api/pcb/upload-bom/${projectComponentHistoryId}`, request)
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            response.data.data.file,
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
          )
        })
    }
  }
}
export const uploadStackupFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadPcb,
        {
          type: "stackup",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)
      Axios.post(
        `/api/pcb/upload-stackup/${projectComponentHistoryId}`,
        request
      )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              file_key: response.data.data.file,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_SUCCESS
          )
        })
        .catch((error) => {
          console.log(error)
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_STACKUP_FILE_ERROR
          )
        })
    }
  }
}

export const uploadAssemblyFileMiddleware = async (
  worker: Worker,
  projectComponentHistoryId: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as any
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadPcb,
        {
          type: "assembly",
          action: "uploaded",
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)
      Axios.post(
        `/api/pcb/upload-assembly/${projectComponentHistoryId}`,
        request
      )
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              file_key: response.data.data.file,
            },
            response.data.data.project_id
          )
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_SUCCESS
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_ASSEMBLY_FILE_ERROR
          )
        })
    }
  }
}

export const getPCBMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  let url = `/api/pcb/${idHistory}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: PCBDetail
  }> = await Axios.get(url)
  const data = response.data.data
  if (!response.data.data.is_encrypted) {
    return data
  }
  const decrypted = await Promise.all(
    data.specifications.map(async (group) => {
      return {
        ...group,
        parts: await Promise.all(
          group.parts.map(async (part) => {
            if (!part.parts) {
              return {
                ...part,
                value: !part.value
                  ? ""
                  : await encryptionController().decrypt(part.value, {
                      dataType: "string",
                      type: "project",
                    }),
              }
            }
            return {
              ...part,
              parts: await Promise.all(
                part.parts.map(async (childPart) => {
                  return {
                    ...childPart,
                    value: !childPart.value
                      ? ""
                      : await encryptionController().decrypt(childPart.value, {
                          dataType: "string",
                          type: "project",
                        }),
                  }
                })
              ),
            }
          })
        ),
      }
    })
  )
  return {
    ...data,
    // layers: await Promise.all(
    //   data.layers.map(async (layer) => {
    //     const fileUrl = getPrivateAssetURI(layer.file, {conversation_id: idConversation})
    //     const content = await getFileContentFromEnrypted(
    //       fileUrl,
    //       layer.file_name,
    //       true
    //     )
    //     return {
    //       ...layer,
    //       file: content as string,
    //     }
    //   })
    // ),
    layers: [],
    tempLayers: data.layers,
    specifications: decrypted,
  }
}

export const deletePcbFile = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.delete(`/api/pcb/${idHistory}`)
  return response.data.data
}

export const getPCBLayerAssignmentsMiddleware = async (
  idConversation?: string
) => {
  let url = `/api/pcb/layer-assignments`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: LayerAsignmentDetail[]
  }> = await Axios.get(url)
  return response.data.data
}

export const putLayerAssignmentMiddleware = (
  request: PutLayerAssignmentRequest,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/pcb/layer-assignments/update`, request)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_ERROR
        )
      }
    })
}

export const putInviteeLayerAssignmentMiddleware = (
  request: PutLayerAssignmentRequest,
  conversationId?: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(
    `/api/invitee/pcb/${conversationId}/layer-assignments/update`,
    request
  )
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_ASIGNMENT_PCB_ERROR
        )
      }
    })
}
export const putSortLayers = (
  request: SortLayerAssignmentRequest,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/pcb/layer-assignments/sort`, request)
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.PUT_SORT_PCB_LAYER_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.PUT_SORT_PCB_LAYER_ERROR
        )
      }
    })
}

export const postDuplicateBuildHistoryMiddleware = (
  idHistory: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: BuildHistoryDetail
  ) => void
) => {
  Axios.post(`/api/project-component/history/${idHistory}/duplicate`)
    .then(
      (
        response: AxiosResponse<{
          data: BuildHistoryDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_DUPLICATE_BUILD_HISTORY_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_DUPLICATE_BUILD_HISTORY_ERROR
      )
    })
}

export const getSpecificationHistoryMiddleware = async (
  idVersion: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: PCBVersionSpecification
  }> = await Axios.get(`/api/specification/history/${idVersion}`, {
    params,
  })
  return response.data.data
}

export const postSpecificationHistoryMiddleware = async (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  console.log("run on submit")
  const encryptedInput = await Promise.all(
    dataRequest.map(async (item) => ({
      ...item,
      value: await encryptionController().encrypt(item.value, {
        dataType: "string",
        type: "project",
      }),
    }))
  )
  Axios.post(`/api/specification/history/${idVersion}/submit`, {
    data: encryptedInput,
  })
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_SAVE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}
export const postInviteeSpecificationHistoryMiddleware = async (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  const encryptedInput = await Promise.all(
    dataRequest.map(async (item) => ({
      ...item,
      value: await encryptionController().encrypt(item.value, {
        dataType: "string",
        type: "project",
      }),
    }))
  )
  Axios.post(
    `/api/invitee/specification/history/${idVersion}/${conversationId}/submit`,
    {
      data: encryptedInput,
    }
  )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.POST_SAVE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}
export const getSpecificationGerberInforMiddleware = async (
  versionId: string
) => {
  const response = await Axios.get(
    `/api/specification/history/${versionId}/gerber-info`
  )
  return response.data.data
}
export const putSpecificationHistoryMiddleware = async (
  idVersion: string,
  dataRequest: any,
  multipart: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  console.log("run on update")
  const isValid = validateSpecificationInput(dataRequest)
  if (typeof isValid === "string") {
    return callback(STATUS_RESPONSE.ERROR, isValid)
  }
  const specificationGerberInfo = await getSpecificationGerberInforMiddleware(
    idVersion
  )
  const { version_name, ...extraInput } = specificationGerberInfo
  const html = toSpecificationHtml(dataRequest, extraInput)
  const htmlBlob = new Blob([html], { type: "text/html" })

  const encryptedInput = await Promise.all(
    dataRequest.map(async (item) => {
      return {
        ...item,
        value: await encryptionController().encrypt(item.value, {
          dataType: "string",
          type: "project",
        }),
      }
    })
  )
  const files = multipart.getAll("files") as File[]
  if (files) {
    let fileHashKeys: any = []
    multipart.delete("files")
    await Promise.all(
      files.map(async (file) => {
        const fileText = await file.text()
        const rawHash = encryptionHelper.createFileHash(fileText)
        const encryptedFile = await encryptionController().encrypt(file, {
          dataType: "file",
          type: "project",
        })
        const blob = new Blob([encryptedFile], { type: file.type })
        multipart.append("files", blob, file.name)
        fileHashKeys = fileHashKeys.concat([
          {
            file_name: file.name,
            file_hash: rawHash,
          },
        ])
        return true
      })
    )
    multipart.append("file_hashs", JSON.stringify(fileHashKeys))
  }
  // add html file
  const encryptedHtmlFile = await encryptionController().encrypt(htmlBlob, {
    dataType: "file",
    type: "project",
  })
  multipart.append(
    "html",
    new Blob([encryptedHtmlFile], { type: htmlBlob.type }),
    `specification_${version_name}.html`
  )
  multipart.append("data", JSON.stringify(encryptedInput))
  Axios.put(`/api/specification/history/${idVersion}/update`, multipart)
    .then((_responseSpecification: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "Save Specification successfully!")
    })
    .catch((errorPutSpecification) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorPutSpecification.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}
export const putInviteeSpecificationHistoryMiddleware = async (
  idVersion: string,
  dataRequest: SpecificationHistoryRequest[],
  multipart: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  const specificationGerberInfo = await getSpecificationGerberInforMiddleware(
    idVersion
  )
  const { version_name, ...extraInput } = specificationGerberInfo
  const html = toSpecificationHtml(dataRequest, extraInput)
  const htmlBlob = new Blob([html], { type: "text/html" })

  const encryptedInput = await Promise.all(
    dataRequest.map(async (item) => {
      return {
        ...item,
        value: await encryptionController().encrypt(item.value, {
          dataType: "string",
          type: "project",
        }),
      }
    })
  )
  const files = multipart.getAll("files") as File[]
  if (files) {
    multipart.delete("files")
    await Promise.all(
      files.map(async (file) => {
        const encryptedFile = await encryptionController().encrypt(file, {
          dataType: "file",
          type: "project",
        })
        const blob = new Blob([encryptedFile], { type: file.type })
        multipart.append("files", blob, file.name)
        return true
      })
    )
  }
  // add html file
  const encryptedHtmlFile = await encryptionController().encrypt(htmlBlob, {
    dataType: "file",
    type: "project",
  })
  multipart.append(
    "html",
    new Blob([encryptedHtmlFile], { type: htmlBlob.type }),
    `specification_${version_name}.html`
  )
  Axios.put(
    `/api/invitee/specification/history/${idVersion}/${conversationId}/update`,
    multipart
  )
    .then((_responseSpecification: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, "Save Specification successfully!")
    })
    .catch((errorPutSpecification) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorPutSpecification.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_SAVE_ERROR
      )
    })
}

// Comment
export const postComment = async (id: string, data: PostCommentBodyData) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-component/${id}/comment`, data)
  return response.data.data
}

export const getComments = async (id: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData[]
  }> = await Axios.get(`/api/project-component/${id}/comment`)
  return response.data.data
}

export const deleteComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.delete(`/api/project-component/comment/${commentId}`)
  return response.data.data
}

export const resolveComment = async (commentId: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData
  }> = await Axios.post(`/api/project-component/comment/${commentId}/resolve`)
  return response.data.data
}

// Other tabs
export const getTabContentMiddleware = async (
  historyId: string,
  type: TabType
) => {
  const response: AxiosResponse<{
    data: GetTabContentMiddlewareData
  }> = await Axios.get(`/api/${type}/${historyId}`)
  return response.data.data
}
// upload mechanical 3d file
export const upload3DFile = async (
  worker: Worker,
  historyId: string,
  type: string, // TabType
  newFile: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const file = newFile.get("file") as any
  newFile.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        newFile.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.Upload3dFile,
        {
          fileName: file.name,
        }
      )
      newFile.append("log", log)
      newFile.append("file_hash", fileHash)

      Axios.post(`/api/${type}/${historyId}/upload-3d`, newFile)
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            {
              file_key: response.data.data.file,
            },
            response.data.data.project_id
          )
          callback(STATUS_RESPONSE.SUCCESS, "")
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

export const delete3DFile = async (
  historyId: string,
  type: string, // TabType
  fileName: string
) => {
  const logMessage = activityLogHelper.toEncryptedMessage(
    ActivityLogType.DeleteMech3DFile,
    {
      fileName: fileName,
    }
  )
  return Axios.delete(`/api/${type}/${historyId}/delete-3d-file`, {
    data: {
      log: logMessage,
    },
  })
}

export const getComponentSubBOMMiddleware = async (
  idHistory: string,
  type: string, // TabType
  idConversation?: string
) => {
  let url = `/api/${type}/${idHistory}/get-bom`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(url)
  return handleBomResponse(response)
}

// upload mechanical sub bom file
export const uploadSubBomFile = async (
  worker: Worker,
  historyId: string,
  type: string, // TabType
  newFile: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const file = newFile.get("file") as any
  newFile.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        newFile.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadSubBom,
        {
          type,
          fileName: file.name,
        }
      )
      newFile.append("log", log)
      newFile.append("file_hash", fileHash)

      Axios.post(`/api/${type}/${historyId}/upload-bom`, newFile)
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            response.data.data.file,
            response.data.data.project_id
          )
          callback(STATUS_RESPONSE.SUCCESS, "")
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

export const deleteSubBomFile = async (
  historyId: string,
  type: string // TabType
) => {
  return Axios.delete(`/api/${type}/${historyId}/delete-bom`, {
    data: {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DeleteMechBom,
        {}
      ),
    },
  })
}

export const postTabContentMiddleware = async (
  worker: Worker,
  historyId: string,
  type: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, error: any) => void
) => {
  const description = data.get("description") as string
  if (description) {
    const encryptedMessage = await encryptionController().encrypt(
      description as string,
      { dataType: "string", type: "project" }
    )
    data.append("description_block", encryptedMessage)
    const encryptedRawMessage = await encryptionController().encrypt(
      description,
      { dataType: "string", type: "project" }
    )
    data.delete("description")
    data.append("description", encryptedRawMessage)
  }
  data.append(
    "log",
    activityLogHelper.toEncryptedMessage(
      ActivityLogType.UploadAttachmentFiles,
      {}
    )
  )
  const files = data.getAll("files") as File[]
  data.delete("files")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: files,
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload

      attachments.forEach((attachment) => {
        data.append("files", attachment.blob, attachment.fileName)
      })
      data.append("file_hashs", JSON.stringify(fileHashs))
      Axios.post<{ data: string }>(`/api/${type}/upload/${historyId}`, data)
        .then(async (response: AxiosResponse) => {
          callback(STATUS_RESPONSE.SUCCESS, "")
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                },
                response.data.data.project_id
              )
              return true
            })
          )
        })
        .catch((error) => {
          callback(STATUS_RESPONSE.ERROR, error)
        })
    }
  }
}

// component types
export const getComponentTypesMiddleware = async () => {
  const response: AxiosResponse<{
    data: TypeSelectProp[]
  }> = await Axios.get(`/api/project-component/types`)
  return response.data.data
}

export const getSummaryComponentAndBuildMiddleware = async (
  idProject: string
) => {
  const response: AxiosResponse<{
    data: {
      components: number
      builds: number
    }
  }> = await Axios.get(`/api/project/${idProject}/summary`)
  return response.data.data
}

export const postNewVersionMiddleware = async (
  id: string,
  type: "project-build-component" | "project-component"
) => {
  const response: AxiosResponse<{
    data
  }> = await Axios.post(
    `/api/project${
      type === "project-build-component" ? "-build-" : "-"
    }component/${id}/history/create-and-copy`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CreateNewVersion,
        {}
      ),
    }
  )
  return response.data.data
}

export const postMergeBuildCpnHistory = async (historyId: string) => {
  const response: AxiosResponse<{
    data
  }> = await Axios.post(
    `/api/project-build-component/history/${historyId}/merge`
  )
  return response.data.data
}

export const deleteFilePCBTemplateMiddleware = (
  idComponentHistory: string,
  url: string,
  callback?: (type: STATUS_RESPONSE, messenger: string) => void,
  fileName?: string,
  type?: string
) => {
  const log = activityLogHelper.toEncryptedMessage(ActivityLogType.UploadPcb, {
    type,
    action: "deleted",
    fileName,
  })
  Axios.put(`/api/pcb/${idComponentHistory}/${url}`, { log })
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, "")
      }
    })
    .catch((error) => {
      if (callback) {
        callback(STATUS_RESPONSE.ERROR, error.response?.data?.message)
      }
    })
}

export const postComponentHistoryCommitMiddleware = async (
  idComponent: string,

  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const encryptedMessage = await encryptionController().encrypt(data.message, {
    dataType: "string",
    type: "project",
  })
  Axios.post(`/api/project-component/history/${idComponent}/commit`, {
    message: encryptedMessage,
  })
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.COMPONENT_COMMIT_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.statusCode === 404
            ? "The draft no longer exists."
            : MESSENGER_NOTIFICATION.COMPONENT_COMMIT_ERROR
        )
      }
    })
}

export const postInviteeComponentHistoryCommitMiddleware = async (
  idComponent: string,
  conversationId: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const encryptedMessage = await encryptionController().encrypt(data.message, {
    dataType: "string",
    type: "project",
  })
  Axios.post(
    `/api/project-build-component/history/${idComponent}/${conversationId}/commit`,
    {
      message: encryptedMessage,
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.SaveVersion,
        {}
      ),
    }
  )
    .then((_response: AxiosResponse) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.COMPONENT_SAVE_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.statusCode === 404
            ? "The draft no longer exists."
            : MESSENGER_NOTIFICATION.COMPONENT_SAVE_ERROR
        )
      }
    })
}

export const postComponentHistoryCreateAndCopyMiddleware = (
  idHistory: string,
  URLHistory: string,
  callback?: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: DataErrorWhenEditHistoryTree
  ) => void
) => {
  Axios.post(
    `/api/project-${URLHistory}/${idHistory}/history/create-and-copy`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.CreateNewVersion,
        {}
      ),
    }
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_BUILD_HISTORY_ERROR,
          error.response?.data
        )
      }
    })
}
export const postComponentHistoryRevertMiddleware = (
  idHistory: string,
  URLHistory: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(
    `/api/project-${URLHistory}/history/${idHistory}/revert`,
    data.message ? data : { message: "" }
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_REVERT_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_REVERT_BUILD_HISTORY_ERROR
        )
      }
    })
}
export const postComponentHistoryTranscendMiddleware = async (
  idComponent: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const encryptedMessage = await encryptionController().encrypt(data.message, {
    dataType: "string",
    type: "project",
  })
  Axios.post(`/api/project-component/history/${idComponent}/transcend`, {
    message: encryptedMessage,
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.Transcend, {}),
  })
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_TRANSCEND_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_TRANSCEND_BUILD_HISTORY_ERROR
        )
      }
    })
}
export const deleteComponentOrVersionMiddleware = (
  idComponent: string,
  type: "component" | "version",
  callback?: (type: STATUS_RESPONSE, messenger: string) => void,
  componentCode?: string
) => {
  Axios.put(
    `/api/project-component/${idComponent}/delete-component-or-version`,
    {
      log: {
        logInComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionInComponent,
          {}
        ),
        logOutComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteDraftVersionOutComponent,
          {}
        ),
        logDeleteComponent: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteComponent,
          { componentCode }
        ),
      },
    }
  )
    .then(() => {
      if (callback) {
        callback(STATUS_RESPONSE.SUCCESS, `Delete ${type} successfully!`)
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? `Delete ${type} failed!`
        )
      }
    })
}

export const getComponentCommentMiddleware = async (urlComponent: string) => {
  const response: AxiosResponse<{
    data: GetCommentsData[]
  }> = await Axios.get(`/api/${urlComponent}`)
  return Promise.all(
    response.data.data.map(async (item) => {
      return {
        ...item,
        content: activityLogHelper.createStringFromTemplate(
          await encryptionController().decrypt(item.content, {
            dataType: "string",
            type: "project",
          }),
          item.param
        ),
      }
    })
  )
}
export const postComponentCommentMiddleware = async (
  urlComment: string,
  data: {
    project_component_history_id: string
    content: string
    conversation_id?: string
    is_public_to_conversation?: boolean
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: GetCommentsData
  ) => void
) => {
  Axios.post(urlComment, {
    ...data,
    content: await encryptionController().encrypt(data.content, {
      dataType: "string",
      type: "project",
    }),
    raw_content: data.content,

    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.SendInvitation,
      {}
    ),
  })
    .then(
      (
        responseComment: AxiosResponse<{
          data: GetCommentsData
        }>
      ) => {
        if (callback) {
          callback(STATUS_RESPONSE.SUCCESS, "", {
            ...responseComment.data.data,
            content: data.content,
          })
        }
      }
    )
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? "Comment failed!"
        )
      }
    })
}
export const postComponentCommentResolveMiddleware = (
  urlComment: string,

  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: GetCommentsData
  ) => void
) => {
  Axios.post(urlComment)
    .then(
      (
        response: AxiosResponse<{
          data: GetCommentsData
        }>
      ) => {
        if (callback) {
          callback(STATUS_RESPONSE.SUCCESS, "", response.data.data)
        }
      }
    )
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ?? "Resolve failed!"
        )
      }
    })
}
export const getProjectComponentOtherMiddleware = async (
  idHistory: string,
  type: string,
  idConversation?: string
) => {
  let url = `/api/${type}/${idHistory}`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }

  const response: AxiosResponse<{
    data: GetTabContentMiddlewareData
  }> = await Axios.get(url)
  const rawDescriptionBlock = await encryptionController().decrypt(
    response.data.data.description,
    { dataType: "string", type: "project" }
  )

  return {
    ...response.data.data,
    description: rawDescriptionBlock,
  }
}
export const getProjectComponentBOMMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(`/api/bom/${idHistory}`)
  return handleBomResponse(response)
}
export const getPCBSubBOMMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  try {
    let url = `/api/pcb/get-bom/${idHistory}`
    if (idConversation) {
      url += `?conversation_id=${idConversation}`
    }

    const response: AxiosResponse<{
      data: BOMDetail
    }> = await Axios.get(url)
    return handleBomResponse(response)
  } catch (error) {
    console.log(error)
  }
}
export const getProjectComponentConversationBOMMiddleware = async (
  idHistory: string,
  idConversation: string
) => {
  const response: AxiosResponse<{
    data: BOMDetail
  }> = await Axios.get(`/api/bom/${idHistory}/${idConversation}`)
  return handleBomResponse(response)
}
export const getHistoriesBuildComponentConvoMiddleware = async (
  idProjectBuildComponent: string,
  idConversation: string
) => {
  const response: AxiosResponse<{
    data: BuildHistoryDetail[]
  }> = await Axios.get(
    `/api/project-build-component/${idProjectBuildComponent}/histories/conversation`,
    {
      params: {
        conversation_id: idConversation,
      },
    }
  )
  return response.data.data
}

export const postBOMUpdateFileMiddleware = async (
  worker: Worker,
  idHistory: string,
  request: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const file = request.get("file") as File
  request.delete("file")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: [file],
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments } = e.data.payload
      const fileHash = fileHashs.length > 0 ? fileHashs[0].file_hash : ""
      attachments.forEach((attachment) => {
        request.append("file", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadBom,
        {
          fileName: file.name,
        }
      )
      request.append("log", log)
      request.append("file_hash", fileHash)

      Axios.post(`/api/bom/upload/${idHistory}`, request, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (response: AxiosResponse) => {
          await validateEncryptedFile(
            fileHash,
            response.data.data.file,
            response.data.data.project_id
          )
          callback(STATUS_RESPONSE.SUCCESS, "Upload file BOM successfully!")
        })
        .catch((errorBOMUpdateFile) => {
          callback(
            STATUS_RESPONSE.ERROR,
            errorBOMUpdateFile.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_ERROR
          )
        })
    }
  }
}

export const deleteFileBOMMiddleware = (
  idHistory: string,
  fileName: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/bom/${idHistory}/delete-file`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.DeleteAttachmentFiles,
      { file: fileName }
    ),
  })
    .then((_responseDeleteFileBOM: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR
      )
    })
}

export const deleteFileOtherMiddleware = (
  idFile: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  conversationId?: string
) => {
  if (conversationId) {
    return Axios.put(
      `/api/invitee/${type}/${conversationId}/delete-file/${idFile}`,
      {
        log: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteAttachmentFiles,
          {}
        ),
      }
    )
      .then((_responseDeleteFileOther: AxiosResponse) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_SUCCESS
        )
      })
      .catch((error) => {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_ERROR
        )
      })
  }
  Axios.put(`/api/${type}/delete-file/${idFile}`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.DeleteAttachmentFiles,
      {}
    ),
  })
    .then((_responseDeleteFileOther: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_FILE_OTHER_ERROR
      )
    })
}

export const postBOMAddSupplierMiddleware = async (
  idHistory: string,
  type: ComponentType = ComponentType.BOM,
  supplierData: any
) => {
  const log = activityLogHelper.toEncryptedMessage(
    ActivityLogType.AddSupplier,
    {
      in:
        type === ComponentType.PCB
          ? "in pcb bom"
          : type === ComponentType.Mechanical
          ? "in mechanical bom"
          : " ",
    }
  )
  const encryptedSupplier = await encryptionController().encrypt(supplierData, {
    dataType: "string",
    type: "project",
  })

  return Axios.post(`/api/${type}/${idHistory}/add-supplier`, {
    log,
    suppliers: encryptedSupplier,
  })
}
export const getBOMAddSupplierMiddleware = async (
  parts: {
    part: string
    qty: number
  }[]
) => {
  return Axios.post(`/api/bom/get-supplier-data`, { parts })
}
export const postBOMAddColumnMiddleware = async (
  idHistory: string,
  dataRequest: TableColumnBOMDetail[],
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM,
  oldData: TableColumnBOMDetail[]
) => {
  const diffColumn = activityLogHelper.toDiffColumn(oldData, dataRequest)
  const log = activityLogHelper.createAddColumnLog(type, diffColumn)
  const newData = await Promise.all(
    dataRequest.map(async (item) => {
      const encryptedValues = await encryptionController().encrypt(
        JSON.stringify(item.values),
        { dataType: "string", type: "project" }
      )
      return {
        ...item,
        values: encryptedValues,
      }
    })
  )

  Axios.post(`/api/${type}/${idHistory}/add-column`, {
    data: newData,
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddColumn, {
      content: log,
    }),
  })
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.ADD_COLUMN_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.ADD_COLUMN_ERROR
      )
    })
}

export const removeSupplierColunmMiddleware = (
  idHistory: string,
  dataRequest: {
    key: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM
) => {
  const log = activityLogHelper.toEncryptedMessage(
    dataRequest.key === "supplier"
      ? ActivityLogType.RemoveSupplierColumn
      : ActivityLogType.RemoveColumn,
    { column: dataRequest.key }
  )
  Axios.put(`/api/${type}/${idHistory}/remove-column`, {
    ...dataRequest,
    log,
  })
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `Delete column ${dataRequest.key.replace(
          "_addColumn",
          ""
        )} successfully!`
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          `Delete column ${dataRequest.key.replace("_addColumn", "")} failed!`
      )
    })
}

export const getBOMExportMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: string
  }> = await Axios.get(`/api/bom/${idHistory}/export`)
  return response.data.data
}

export const postBOMSyncDataMiddleware = async (
  idHistory: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  type: ComponentType = ComponentType.BOM,
  supplierData: any
) => {
  const encryptedSupplier = await encryptionController().encrypt(supplierData, {
    dataType: "string",
    type: "project",
  })
  ///
  Axios.post(`/api/${type}/${idHistory}/add-supplier`, {
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddSupplier, {
      in:
        type === ComponentType.PCB
          ? "in pcb bom"
          : type === ComponentType.Mechanical
          ? "in mechanical bom"
          : " ",
    }),
    suppliers: encryptedSupplier,
  })
    .then(() => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.SYNC_DATA_BOM_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.SYNC_DATA_BOM_ERROR
      )
    })
}
export const putBOMUpdateCellMiddleware = (
  idHistory: string,
  dataRequest: {
    index: number
    key: string
    value: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/bom/${idHistory}/update-cell`, dataRequest)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BOM_COLUMN_ERROR
      )
    })
}
export const putBOMUpdateColumnMiddleware = (
  idHistory: string,
  dataRequest: {
    key: string
    new_key: string
  },
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/bom/${idHistory}/update-column`, dataRequest)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "")
    })
    .catch((errorUpdateColumn) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorUpdateColumn.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BOM_COLUMN_ERROR
      )
    })
}

export const getProjectBuildListLinkedBuildMiddleware = async (
  idComponent: string,
  params?: ParamsBuildHistoryProps
) => {
  const response: AxiosResponse<{
    data: ProjectBuildDetail[]
  }> = await Axios.get(
    `api/project-build/get-list-linked-build/${idComponent}`,
    {
      params,
    }
  )
  return response.data.data
}

export const postBuildComponentMergeMiddleware = async (
  idHistory: string,
  data: {
    message: string
  },
  callback?: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const encryptedMessage = await encryptionController().encrypt(data.message, {
    dataType: "string",
    type: "project",
  })
  Axios.post(
    `/api/project-build-component/history/${idHistory}/merge`,
    data.message
      ? {
          message: encryptedMessage,
        }
      : null
  )
    .then(() => {
      if (callback) {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_MERGE_BUILD_HISTORY_SUCCESS
        )
      }
    })
    .catch((error) => {
      if (callback) {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            MESSENGER_NOTIFICATION.POST_MERGE_BUILD_HISTORY_ERROR
        )
      }
    })
}

export const getBOMCurrenciesMiddleware = async (listCurrencies: string[]) => {
  const response: AxiosResponse<{
    data: SelectCurrencyDetail[]
  }> = await Axios.get(`/api/bom/currencies`, {
    params: {
      filter: {
        symbols: listCurrencies,
      },
    },
  })
  return response.data.data
}

export const postInviteeBOMComponentSyncMiddleware = (
  idProjectComponentHistory: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/bom/${idProjectComponentHistory}/sync`)
    .then(() => {
      callback(STATUS_RESPONSE.SUCCESS, "Sync BOM detail table successfully!")
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Sync BOM detail table failed!"
      )
    })
}

export const getPCBBOMMiddleware = async (idHistory: string) => {
  const response: AxiosResponse<{
    data: any
  }> = await Axios.get(`/api/pcb/get-bom/${idHistory}`)
  return response.data.data
}

export const getIsReadBuildComponentMiddleware = async (
  idHistory: string,
  idConversation?: string
) => {
  let url = `/api/project-build-component/${idHistory}/get-is-read`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: {
      is_read_invitee_comment: boolean
      is_read_comment: boolean
    }
  }> = await Axios.get(url)
  return response.data.data
}

export const getListUserWorkingMiddleware = (
  requestParams: ParamUserWorkingItem,
  callback: (dataRes: UserWorkingItem[]) => void
) => {
  Axios.post(`/api/user/current-active`, requestParams)
    .then(
      (
        response: AxiosResponse<{
          data: UserWorkingItem[]
        }>
      ) => {
        callback(response.data.data)
      }
    )
    .catch((_error) => {
      callback([])
    })
}

export const uploadAttachmentsMiddleware = async (
  worker: Worker,
  historyId: string,
  data: FormData,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  let fileString = ""
  const files = data.getAll("files") as File[]
  data.delete("files")

  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: files,
      isRawFile: true,
      localStorageData: getWorkerLocalStorageData(),
      fileOption: {
        dataType: "file",
        type: "project",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments, fileString } = e.data.payload
      attachments.forEach((attachment) => {
        data.append("files", attachment.blob, attachment.fileName)
      })
      const log = activityLogHelper.toEncryptedMessage(
        ActivityLogType.UploadAttachmentFiles,
        { file: fileString }
      )
      data.append("log", log)
      data.append("file_hashs", JSON.stringify(fileHashs))
      Axios.post(`/api/pcb/upload-attachments/${historyId}`, data)
        .then(async (response: AxiosResponse) => {
          callback(
            STATUS_RESPONSE.SUCCESS,
            MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_SUCCESS
          )
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                },
                response.data.data.project_id
              )
              return true
            })
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.UPLOAD_ATTACHMENT_ERROR
          )
        })
    }
  }
}
