export const MESSENGER_NOTIFICATION = {
  EMAIL_EXIST: "Email already exists!",
  PHONE_NUMBER: "Phone number formatted incorrectly",
  PASSWORD_VALIDATE:
    "Passwords must be at least 8 characters long, at least one uppercase letter, one lowercase letter, one special character and one number!",
  UPDATING_INFORMATION_SUCCESS: "Updated information successfully",
  CHANGE_PASSWORD_SUCCESS: "Change password successfully",
  RECAPTCHA_AVAILABLE: "Execute recaptcha not yet available!",
  IMAGE_INVALID: "Image invalid!",
  FILE_INVALID: "File invalid!",
  CHECKBOX_SIGN_UP:
    "Please indicate that you agree to the terms and conditions",
  PASSWORD_NOT_MATCH: "The password confirmation does not match!",
  PASSWORD_MATCH: "The password confirmation match!",
  POST_PROJECT_SUCCESS: "Create new project successfully!",
  POST_PROJECT_ERROR: "Create new project failed!",
  PUT_PROJECT_SUCCESS: "Updated project successfully!",
  PUT_PROJECT_ERROR: "Updated project failed!",
  DELETE_PROJECT_SUCCESS: "Delete project successfully!",
  DELETE_PROJECT_ERROR: "Delete project failed!",
  POST_PROJECT_COMPONENT_SUCCESS: "Create project component successfully!",
  POST_PROJECT_COMPONENT_ERROR: "Create project component failed!",
  PUT_PROJECT_COMPONENT_SUCCESS: "Updated project component successfully!",
  PUT_PROJECT_COMPONENT_ERROR: "Updated project component failed!",
  DESCRIPTION_ERROR: `Let's maximum the project's description is 150 letters`,
  POST_BUILD_HISTORY_SUCCESS: "Create new version successfully!",
  POST_BUILD_HISTORY_ERROR: "Create new version failed!",
  POST_DUPLICATE_BUILD_HISTORY_SUCCESS: "Duplicate version successfully!",
  POST_DUPLICATE_BUILD_HISTORY_ERROR: "Duplicate version failed!",
  POST_BUILD_SUCCESS: "Created new build successfully!",
  POST_BUILD_ERROR: "Created new build failed!",
  PUT_BUILD_PRODUCTION_SUCCESS: "Project build to in production successfully!",
  PUT_BUILD_PRODUCTION_ERROR: "Project build to in production failed!",
  POST_PCB_SUCCESS: "Updated file PCB successfully!",
  POST_PCB_ERROR: "Updated file PCB failed!",
  ADD_PCB_FILE_SUCCESS: "Upload file PCB successfully!",
  ADD_PCB_FILE_ERROR: "Upload file PCB failed!",
  DELETE_PCB_FILE_SUCCESS: "Delete file successfully!",
  DELETE_PCB_FILE_ERROR: "Delete file failed!",
  PUT_ASIGNMENT_PCB_SUCCESS: "Change layer asignment successfully!",
  PUT_ASIGNMENT_PCB_ERROR: "Change layer asignment failed!",
  PUT_SORT_PCB_LAYER_SUCCESS: "Sort pcb layer successfully!",
  PUT_SORT_PCB_LAYER_ERROR: "Sort pcb layer failed!",
  POST_DUPLICATE_COMPONENT_SUCCESS: "Duplicate project component successfully!",
  POST_DUPLICATE_COMPONENT_ERROR: "Duplicate project component failed!",
  POST_SAVE_SUCCESS: "Save Specification successfully!",
  POST_SAVE_ERROR: "Save Specification failed!",
  UPDATE_FILE_PCB_ERROR: "Wrong file format! Files allowed:",
  FILE_NOT_ALLOW: "Wrong file format! Files not allowed:",
  POST_COPY_LINK_SUCCESS: "Copy link successfully!",
  POST_COPY_LINK_ERROR: "Copy link failed!",
  POST_INVITE_USER_SUCCESS: "Invite user successfully!",
  POST_INVITE_USER_ERROR: "Invite user failed!",
  POST_CHANGE_ROLE_SUCCESS: "Role was successfully updated!",
  POST_CHANGE_ROLE_ERROR: "Role was failed updated!",
  POST_CHANGE_BUILD_STATUS_SUCCESS: "Change build status successfully!",
  POST_CHANGE_BUILD_STATUS_ERROR: "Change build status failed!",
  POST_COMMIT_BUILD_HISTORY_SUCCESS: "Commit version successfully!",
  POST_COMMIT_BUILD_HISTORY_ERROR: "Commit version failed!",
  POST_REVERT_BUILD_HISTORY_SUCCESS: "Version has been reverted successfully!",
  POST_REVERT_BUILD_HISTORY_ERROR: "Version has been reverted failed!",
  POST_TRANSCEND_BUILD_HISTORY_SUCCESS:
    "Version has been duplicated successfully!",
  POST_TRANSCEND_BUILD_HISTORY_ERROR: "Version has been duplicated failed!",
  POST_MERGE_BUILD_CPN_HISTORY_SUCCESS: "Merge version successfully!",
  POST_MERGE_BUILD_CPN_HISTORY_ERROR: "Merge version failed!",
  PUT_ADD_COMPONENT_IN_BUILD_SUCCESS:
    "Add component in project build successfully!",
  PUT_ADD_COMPONENT_IN_BUILD_ERROR: "Add component in project build failed!",
  FORGOT_PASSWORD_SUCCESS: "Please check email for reset password link",
  REQUICE_CREATE_CATEGORY_ERROR: "Must select at least one category!",
  POST_CATEGORY_ERROR: "Create Category failed!",
  ARCHIVE_PROJECT_SUCCESS: "Archive project successfully!",
  ARCHIVE_PROJECT_ERROR: "Archive project failed!",
  UN_ARCHIVE_PROJECT_SUCCESS: "Unarchive project successfully!",
  UN_ARCHIVE_PROJECT_ERROR: "Unarchive project failed!",
  FAVORITE_PROJECT_ERROR: "Favorite project failed!",
  PUT_UPDATE_STATUS_PROJECT_SUCCESS: "Updated status project successfully!",
  PUT_UPDATE_STATUS_PROJECT_ERROR: "Updated status project failed!",
  VIEW_ONLY:
    "You don't have permission. Please contact owner or switch accounts!",
  ARCHIVE_PROJECT_COMPONENT_SUCCESS: "Archive component successfully!",
  ARCHIVE_PROJECT_COMPONENT_ERROR: "Archive component failed!",
  UN_ARCHIVE_PROJECT_COMPONENT_SUCCESS: "Unarchive component successfully!",
  UN_ARCHIVE_PROJECT_COMPONENT_ERROR: "Unarchive component failed!",
  UPLOAD_BOM_FILE_SUCCESS: "Upload file BOM successfully!",
  UPLOAD_BOM_FILE_ERROR: "Upload file BOM failed!",
  UPLOAD_3D_FILE_SUCCESS: "Upload file 3D successfully!",
  UPLOAD_3D_FILE_ERROR: "Upload file 3D failed!",
  UPLOAD_ATTACHMENT_SUCCESS: "Upload file Attachment successfully",
  UPLOAD_ATTACHMENT_ERROR: "Upload file Attachment failed",
  UPLOAD_STACKUP_FILE_SUCCESS: "Upload file Stackup successfully!",
  UPLOAD_STACKUP_FILE_ERROR: "Upload file Stackup failed!",
  UPLOAD_ASSEMBLY_FILE_SUCCESS: "Upload file Assembly successfully!",
  UPLOAD_ASSEMBLY_FILE_ERROR: "Upload file Assembly failed!",
  DELETE_GERBER_FILE_SUCCESS: "Delete file Gerber successfully!",
  DELETE_3D_FILE_SUCCESS: "Delete file 3D successfully!",
  DELETE_3D_FILE_ERROR: "Delete file 3D failed!",
  DELETE_GERBER_FILE_ERROR: "Delete file Gerber failed!",
  DELETE_BOM_FILE_SUCCESS: "Delete file BOM successfully!",
  DELETE_BOM_FILE_ERROR: "Delete file BOM failed!",
  DELETE_STACKUP_FILE_SUCCESS: "Delete file Stackup successfully!",
  DELETE_STACKUP_FILE_ERROR: "Delete file Stackupo failed!",
  DELETE_ASSEMBLY_FILE_SUCCESS: "Delete file Assembly successfully!",
  DELETE_ASSEMBLY_FILE_ERROR: "Delete file Assembly failed!",
  COMPONENT_COMMIT_SUCCESS: "Version has been committed successfully!",
  COMPONENT_COMMIT_ERROR: "Version has been committed failed!",
  COMPONENT_SAVE_SUCCESS: "Version has been saved successfully!",
  COMPONENT_SAVE_ERROR: "Version has been saved failed!",
  CHANGE_VERSION_TO_DRAFT_SUCCESS: "Change version to Draft successfully!",
  CHANGE_VERSION_TO_DRAFT_ERROR: "Change version to Draft failed!",
  DELETE_FILE_OTHER_SUCCESS: "Delete file successfully!",
  DELETE_FILE_OTHER_ERROR: "Delete file failed!",
  UPDATE_BUILD_SUCCESS: "Update build successfully!",
  UPDATE_BUILD_ERROR: "Update build failed!",
  DELETE_COMPONENT_IN_BUILD_SUCCESS: "Delete component in build successfully!",
  DELETE_COMPONENT_IN_BUILD_ERROR: "Delete component in failed!",
  DUPLICATE_BUILD_SUCCESS: "Duplicate build successfully!",
  DUPLICATE_BUILD_ERROR: "Duplicate build failed!",
  ARCHIVE_BUILD_SUCCESS: "Archive build successfully!",
  ARCHIVE_BUILD_ERROR: "Archive build failed!",
  UN_ARCHIVE_BUILD_SUCCESS: "Unarchive build successfully!",
  UN_ARCHIVE_BUILD_ERROR: "Unarchive build failed!",
  PERMISSION_PROJECT:
    "This project is archived. Please unarchive for further action!",
  PERMISSION_COMPONENT:
    "This component is archived. Please unarchive for further action!",
  PERMISSION_BUILD:
    "This build is archived. Please unarchive for further action!",
  PERMISSION_NO_UPDATE_FILE_PCB: "Please edit version before adding column!",
  ADD_SUPPLIER_SUCCESS: "Add supplier successfully!",
  ADD_SUPPLIER_ERROR: "Add supplier failed!",
  ADD_COLUMN_SUCCESS: "Add column successfully!",
  ADD_COLUMN_ERROR: "Add column failed!",
  SYNC_DATA_BOM_SUCCESS: "Sync supplier data successfully!",
  SYNC_DATA_BOM_ERROR: "Sync supplier data failed!",
  UPDATE_BOM_COLUMN_ERROR: "Update column failed!",
  CREATE_ADDITIONAL_INFO_ERROR: "Create additional info failed!",
  DELETE_ADDITIONAL_INFO_ERROR: "Delete additional info failed!",
  DELETE_ADDITIONAL_INFO_SUCCESS: "Delete additional info successfully!",
  CREATE_EXTRA_INFO_ERROR: "Create extra info failed!",
  DELETE_EXTRA_INFO_ERROR: "Delete extra info failed!",
  DELETE_EXTRA_INFO_SUCCESS: "Delete extra info successfully!",
  CREATE_INVITEE_ERROR: "Add invitee failed!",
  CREATE_INVITEE_SUCCESS: "Add invitee successfully!",
  UPDATE_INVITEE_ERROR: "Update invitee failed!",
  UPDATE_INVITEE_SUCCESS: "Update invitee successfully!",
  ADD_COMPONENT_INVITEE_ERROR: "Add component failed!",
  ADD_COMPONENT_INVITEE_SUCCESS: "Add component successfully!",
  DELETE_COMPONENT_INVITEE_ERROR: "Delete component failed!",
  DELETE_COMPONENT_INVITEE_SUCCESS: "Delete component successfully!",
  POST_MERGE_BUILD_HISTORY_SUCCESS: "Version has been merged successfully!",
  POST_MERGE_BUILD_HISTORY_ERROR: "Version has been merged failed!",
  FAVORITE_PROJECT_BUILD_ERROR: "Favorite build failed!",
  CONVERSATION_SEND_MESSAGE_ERROR: "Send message failed!",
  SYNC_DATA_INVITEE_ERROR: "Synced data to invitee failed!",
  SYNC_DATA_INVITEE_SUCCESS: "Synced data to invitee successfully!",
  SHARE_REMOVE_USER_ERROR: "Remove user failed!",
  SHARE_REMOVE_USER_SUCCESS: "Remove user successfully!",
  GENERAL: {
    UKNOWN_ERROR: "Something went wrong! Please contact the administrator",
  },
  DUPLICATE_DRAFT_COMPONENT:
    "It is not possible to duplicate this draft component",
  VALIDATE: {
    INVALID_EMAIL: "Invalid Email!",
  },
  PUT_FOLDER_ERROR: "Update folder name failed",
  PUT_FOLDER_SUCCESS: "Update folder name successfully",
  INVALID_KEY_ENCRYPTION: "Encryption key is not valid, please contact the administrator.",
  INCORRECT_FILE_CONTENT:
    "Something wrong with the file content. Please contact to the Administator for more information.",
}
